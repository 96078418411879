import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { capitalizeFirstLetter, mapEnum, SplitNumber } from '@core/utils'
import { postCalculateLastDay } from '@services/payroll.service'
import moment from 'moment'
import React, { memo, useContext, useEffect, useState } from 'react'
import { CgTrash } from 'react-icons/cg'
import { MdChevronRight, MdFiberManualRecord, MdOutlineEdit } from 'react-icons/md'
import { useMutation } from 'react-query'
import styled from 'styled-components'

import Form from './Form'

export const parseStatus = (status) => {
  if (status === 'CREATED' || status === 'MOVED_TO_NEXT_MONTH' || status === 'PENDING') {
    return 'created'
  }
  if (status === 'APPROVED_BY_COMPANY' || status === 'AWAITING_PAYMENT') {
    return 'AWAITING_PAYMENT'
  }
  if (status === 'PAID') {
    return 'PAID'
  }
  if (status === 'REJECTED' || status === 'CANCELLED' || status === 'DELETED') {
    return 'Cancelled'
  }
  return 'draft'
}

const Styled = styled.div`
  margin: 8px 0px;
`
const Header = styled.div`
  border: 1px solid #f3f3f3;
  border-radius: ${(p) => (p.open ? '8px 8px 0px 0px' : '8px')};
  padding: 16px;
  outline: none;
  background: ${(p) => (p.open ? '#F9F9F9' : '#fff')};
`
const Body = styled.div`
  padding: 16px;
  background: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0px 0px 8px 8px;
  display: ${(p) => (p.open ? 'block' : 'none')};
`
const StyledIcon = styled(MdChevronRight)`
  transform: ${(p) => p.open && 'rotate(90deg)'};
  color: #b2b2b2;
  margin-right: 8px;
`
const StyledBtn = styled(Button)`
  border: ${(p) => (p.open ? 'none' : '1px solid #F3F3F3')};
  padding: 6px 16px;
`

const Item = memo(({ payment, isLoading, payrollData, onDelete, onSave, expandedId, setPaymentId }) => {
  const { profile } = useContext(AppContext)
  const currentDate = new Date(moment(new Date()).format('YYYY-MM-DD'))
  const isOpen = expandedId === payment.id

  const [isDisable, setIsDisable] = useState(false)
  const handleToggle = (id) => () => setPaymentId(isOpen ? null : id)

  const postCalculateLastDayMutate = useMutation('postCalculateLastDay', {
    mutationFn: (payload) => postCalculateLastDay(payload),
  })

  useEffect(() => {
    if (profile) {
      postCalculateLastDayMutate.mutate({
        country: profile?.legal_address?.living_country?.id,
        days: profile.additional_payment_cut_off_day,
      })
    }
  }, [profile])

  useEffect(() => {
    if (
      postCalculateLastDayMutate?.data?.last_day &&
      currentDate.getTime() >= new Date(postCalculateLastDayMutate?.data?.last_day).getTime()
    ) {
      setIsDisable(true)
    } else setIsDisable(false)
  }, [postCalculateLastDayMutate?.data])
  return (
    <Styled key={payment.id}>
      <Header className="d-flex align-items-center" open={isOpen}>
        <div className="flex-grow-1" role="button" aria-hidden onClick={handleToggle(payment.id)}>
          <StyledIcon open={isOpen} />
          <Typography as="span" className={`${isOpen ? 'heading_semibold__18' : 'text_medium__14'}`}>
            {payment.name}
          </Typography>
          <MdFiberManualRecord style={{ margin: '0 4px', height: 12 }} />
          <Typography as="span" className={`${isOpen ? 'heading_semibold__18' : 'text_medium__14'}`}>
            {mapEnum(capitalizeFirstLetter(payment.ap_type))}
          </Typography>
          <MdFiberManualRecord style={{ margin: '0 4px', height: 12 }} />
          <Typography as="span" className={`${isOpen ? 'heading_semibold__18' : 'text_medium__14'}`}>
            {payrollData?.company_currency?.sign || payrollData?.company_currency?.short_code}
            {SplitNumber(payment.amount_in_company_currency)}
          </Typography>
        </div>
        <StyledBtn
          open={isOpen}
          size="small"
          priority="secondary"
          type="button"
          disabled={
            !(
              !(isDisable && payrollData?.state === 'created') &&
              payrollData?.state !== 'approved_by_partner' &&
              payrollData?.state !== 'approved_by_admin'
            )
          }
          style={{ marginRight: 10 }}
          onClick={handleToggle(payment.id)}
        >
          <MdOutlineEdit style={{ marginRight: 10 }} />
          {isOpen ? 'Editing...' : 'Edit'}
        </StyledBtn>
        <StyledBtn
          size="small"
          priority="danger_outlined"
          disabled={
            !(
              !(isDisable && payrollData?.state === 'created') &&
              payrollData?.state !== 'approved_by_partner' &&
              payrollData?.state !== 'approved_by_admin'
            )
          }
          onClick={() => onDelete(payment.id)}
        >
          <CgTrash style={{ marginRight: 10 }} />
          Delete
        </StyledBtn>
      </Header>
      <Body open={isOpen}>
        <Form
          data={payment}
          isLoading={isLoading}
          payrollData={payrollData}
          isDisabled={
            !(
              !(isDisable && payrollData?.state === 'created') &&
              payrollData?.state !== 'approved_by_partner' &&
              payrollData?.state !== 'approved_by_admin'
            )
          }
          onClose={handleToggle(null)}
          onSave={onSave}
        />
      </Body>
    </Styled>
  )
})

const MyAccordion = ({ payroll, isLoading, onSave, onDelete }) => {
  const [expandedItemId, setExpandedItemId] = useState(null)

  return payroll.additional_payments.map((v) => {
    return (
      <Item
        key={v.id}
        isLoading={isLoading}
        payment={v}
        payrollData={payroll}
        // currency={payroll.company_currency}
        // state={payroll.state}
        onDelete={onDelete}
        onSave={(body) => onSave({ id: v.id, body })}
        expandedId={expandedItemId}
        setPaymentId={setExpandedItemId}
      />
    )
  })
}

MyAccordion.displayName = 'Accardion'

export default memo(MyAccordion)
