import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import Confirm from '@atoms/Confirm'
import Icon from '@atoms/Icon/Icon'
import Pagination from '@atoms/Pagination/Pagination'
import { AbsoluteSpinner } from '@atoms/Spinner/AbsoluteSpinner'
import Typography from '@atoms/Typography/Typography'
import { EXPENSE_STATUSES } from '@core/constants'
import { keyboardArrowLeft } from '@core/icons/icons'
import ExpenseStatus from '@pages/employeesExpense/expense-status/ExpenseStatus'
import moment from 'moment'
import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import ExpenseEvent from './ExpenseEvent'

const Bulk = styled.div`
  padding: 8px 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Styled = styled.div`
  margin: 8px 0;
`
const Header = styled.div`
  border: 1px solid #f3f3f3;
  border-radius: ${(p) => (p.open ? '8px 8px 0px 0px' : '8px')};
  padding: 16px;
  outline: none;
  background: #fff;
`
const Body = styled.div`
  padding: 16px;
  background: #f9f9f9;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0 0 8px 8px;
  display: ${(p) => (p.open ? 'block' : 'none')};
`

const Item = memo(({ isBulk, expense, expandedId, onOpen, refetch, onCheck }) => {
  const isOpen = expandedId === expense.id

  const handleToggle = (id) => () => onOpen(isOpen ? null : id)

  const handleCheck = ({ target }) => [onCheck(Number(target.id), target.checked)]

  return (
    <Styled key={expense.id}>
      <Header className="d-flex align-items-center" open={expense.isOpen}>
        <div className="row w-100 align-items-center" role="button" aria-hidden onClick={handleToggle(expense.id)}>
          <div className="col-2 d-flex align-items-center">
            <div className="d-flex gap-2">
              {isBulk && (
                <Checkbox
                  data-testid="ExpenseAccordion-C9F73D"
                  id={expense.id}
                  checked={expense.checked}
                  disabled={expense.state !== EXPENSE_STATUSES.PENDING}
                  onChange={handleCheck}
                />
              )}
              <Icon
                fill="#B2B2B2"
                style={{
                  cursor: 'pointer',
                  transform: expandedId === expense.id ? 'rotate(90deg)' : 'rotate(0deg)',
                }}
                icon={keyboardArrowLeft}
              />
            </div>
            <div className="ml-3 d-flex flex-column">
              <Typography as="span" className="text_medium__14">
                {expense.contract.full_name}
              </Typography>
              {expense.contract_type !== 'FTE' && (
                <Typography as="span" className="text_light__13 color_text_300">
                  Contractor
                </Typography>
              )}
            </div>
          </div>
          <div className="col-2">
            <Typography as="span" className="text_regular-normal__14">
              {expense.expense_name}
            </Typography>
          </div>
          <div className="col-2">
            <Typography as="span" className="text_regular-normal__14">
              {moment(expense.created_at).format('DD MMM YYYY')}
            </Typography>
          </div>
          <div className="col-1">
            <Typography as="span" className="text_regular-normal__14">
              {expense.currency.short_code || expense.currency.sign} {expense.amount}
            </Typography>
          </div>
          <div className="col-2 d-flex flex-column">
            <Typography as="span" className="text_regular-normal__14">
              {expense.created_by?.full_name}
            </Typography>
            {expense.created_by?.organization_name && (
              <Typography as="span" className="text_light__13 color_text_300">
                {expense.created_by?.organization_name}
              </Typography>
            )}
          </div>
          <div className="col-1 justify-content-end d-flex">
            {expense?.payout_date ? moment(expense.payout_date).format('MMMM') : '-'}
          </div>
          <div className="col-2 justify-content-end d-flex">
            <ExpenseStatus status={expense.payout_date === 'N/A' ? 'awaiting_approval' : expense.state} />
          </div>
        </div>
      </Header>
      <Body open={isOpen}>
        <ExpenseEvent expense={expense} refetch={refetch} />
      </Body>
    </Styled>
  )
})

const MyAccordion = ({ isBulk, expenses, createdExpenseId, bulkApprove, isLoading, refetch, children, pagination }) => {
  const [expandedItemId, setExpandedItemId] = useState(null)
  const [keyValueExpenses, setKeyValueExpenses] = useState({})
  const [pageToConfirm, setPageToConfirm] = useState(null)

  const approveConfirm = useBoolean(false)

  useEffect(() => {
    setKeyValueExpenses(
      expenses.reduce(
        (acc, expense) => ({
          ...acc,
          [expense.id]: { ...expense, isOpen: false, checked: false },
        }),
        {}
      )
    )
  }, [expenses])

  const allPendingExpenses = Object.values(keyValueExpenses).filter(({ state }) => state === EXPENSE_STATUSES.PENDING)

  const isDetermine = allPendingExpenses.some(({ checked }) => checked)

  const allChecked = allPendingExpenses.length > 0 && allPendingExpenses.every(({ checked }) => checked)

  useEffect(() => {
    if (createdExpenseId) setExpandedItemId(createdExpenseId)
  }, [createdExpenseId])

  const handleCheck = (expenseId, checked) => {
    setKeyValueExpenses((prev) => ({
      ...prev,
      [expenseId]: { ...prev[expenseId], checked },
    }))
  }

  const handleCheckAll = ({ target }) => {
    setKeyValueExpenses((prev) =>
      Object.entries(prev).reduce(
        (acc, [expenseId, expense]) => ({
          ...acc,
          [expenseId]: {
            ...expense,
            checked: expense.state === EXPENSE_STATUSES.PENDING ? target.checked : false,
          },
        }),
        prev
      )
    )
  }

  const handleBulkApprove = () => {
    bulkApprove({
      expenses: Object.values(keyValueExpenses)
        .filter(({ checked }) => checked)
        .map(({ id }) => id),
    })

    approveConfirm.setFalse()
  }

  // eslint-disable-next-line no-shadow
  const handlePage = (page) => {
    if (isDetermine) {
      setPageToConfirm(page)
    } else {
      pagination.setPage(page)
    }
  }

  const handleYes = () => {
    setPageToConfirm(null)
    pagination.setPage(pageToConfirm)
  }

  return (
    <>
      <AbsoluteSpinner isFetching={isLoading} className="d-flex flex-column gap-2">
        {children}

        {isBulk && (
          <Bulk>
            <Checkbox
              data-testid="ExpenseAccordion-433E35"
              disabled={allPendingExpenses.length === 0}
              checked={allChecked}
              determine={isDetermine}
              onChange={handleCheckAll}
            />
            <Button
              data-testid="ExpenseAccordion-0BF537"
              disabled={!isDetermine || isLoading}
              size="small"
              onClick={approveConfirm.setTrue}
            >
              Approve selected
            </Button>
          </Bulk>
        )}
        {expenses
          .map(({ id }) => keyValueExpenses[id])
          .filter((expense) => !!expense)
          .map((expense) => {
            return (
              <Item
                isBulk={isBulk}
                key={expense.id}
                expense={expense}
                expandedId={expandedItemId}
                refetch={refetch}
                onOpen={setExpandedItemId}
                onCheck={handleCheck}
              />
            )
          })}

        {pagination.count && (
          <div className="row align-items-center justify-content-end mt-3">
            <div className="col-auto">
              <Pagination
                total={pagination.count}
                pageSize={pagination.limit}
                page={pagination.page}
                onGetPage={handlePage}
              />
            </div>
          </div>
        )}
      </AbsoluteSpinner>
      {pageToConfirm && (
        <Confirm
          title="Leave page?"
          yesButtonProps={{ priority: 'primary' }}
          onYes={handleYes}
          onCancel={() => setPageToConfirm(null)}
        >
          Changes that you made might not be saved”
        </Confirm>
      )}

      {approveConfirm.value && (
        <Confirm
          title="Approve expenses?"
          yesButtonProps={{ priority: 'primary' }}
          onYes={handleBulkApprove}
          onCancel={approveConfirm.setFalse}
        >
          {`Are you sure you want to approve these ${
            allPendingExpenses.filter(({ checked }) => checked).length
          } expense requests? Please note
          that this action cannot be undone.`}
        </Confirm>
      )}
    </>
  )
}

MyAccordion.displayName = 'Accordion'

export default memo(MyAccordion)
