import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { AutomatedInvoicingIcon } from '@core/icons/icons'
import moment from 'moment'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const formatDate = (date) => moment(date, 'YYYY-MM-DD').format('D MMM YYYY')

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 0;
    width: 504px;
  }
`
export const InvoiceCreateErrorsModal = ({ onClose, onOk, errors }) => {
  return (
    <StyledModal show onHide={onClose} centered className="p-0">
      <Modal.Header closeButton style={{ padding: '32px 40px 0px' }}>
        <Typography className="heading_semibold__24">Unpaid invoice</Typography>
      </Modal.Header>
      <Modal.Body style={{ padding: '4px 40px' }}>
        <Typography className="text_regular__14 d-flex flex-column gap-3">
          <Icon icon={AutomatedInvoicingIcon} />
          <div>
            You have unpaid invoice(s) for:
            <Typography className="text_medium__14">
              <ul className="mb-0">
                {errors.map((error) => (
                  <li>{`{${error.full_name}, ${formatDate(error.date)}, ${error.currency.sign}${error.amount}}`}</li>
                ))}
              </ul>
            </Typography>
          </div>
          <p>
            Please pay the pending invoice(s) as soon as possible. Once funds are received, you can create new payments.
          </p>
          <p>Alternatively, cancel the invoice(s), create a new payment, and group both payments into a new invoice.</p>
        </Typography>
      </Modal.Body>
      <Modal.Footer style={{ padding: '44px 40px 32px' }}>
        <Button
          data-testid="InvoiceCreateErrorsModal-3067C2"
          type="button"
          size="small"
          priority="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button data-testid="InvoiceCreateErrorsModal-12A418" size="small" priority="primary" onClick={onOk}>
          Go to invoices
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
