// eslint-disable-next-line import/no-cycle
import { CreateContract } from '@features/agreements/create-contract/create-contract'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

import ContractFormSelect from './contract-form-select/contract-form-select'
import { CreateContractModal } from './create-contract-modal/create-contract-modal'
import { InviteContractorModal } from './invite-contractor-modal/invite-contract-modal'
import { UploadContract } from './upload-contract/upload-contract'

export const ContractForm = ({
  updateContractOpen,
  contractor,
  onClose,
  withSelectCancelButton,
  onCancel,
  cancelButtonLabel,
  isCancelling,
  inviteContractorOpen,
  onFinish,
  invitedContractor,
  loading,
  draftId,
  draft,
  invitedContractId,
  isDraftContractLoading,
}) => {
  const [contractType, setContractType] = useState('create')
  const contractBeforeModalOpen = useBoolean(false)
  const createContractOpen = useBoolean(false)
  const [draftContractId, setDraftContractId] = useState(null)
  const navigate = useNavigate()

  return (
    <>
      {!updateContractOpen.value && (
        <ContractFormSelect
          onNext={contractType === 'upload' ? updateContractOpen.setTrue : contractBeforeModalOpen.setTrue}
          setContractType={setContractType}
          contractType={contractType}
          withCancelButton={withSelectCancelButton}
          onCancel={onCancel}
          cancelButtonLabel={cancelButtonLabel}
          isCancelling={isCancelling}
        />
      )}
      {updateContractOpen.value && contractType === 'upload' && (
        <UploadContract onClose={onClose} contractor={contractor} draftContractId={draftContractId} />
      )}
      {contractBeforeModalOpen.value && (
        <CreateContractModal onClose={contractBeforeModalOpen.setFalse} onSave={createContractOpen.setTrue} />
      )}
      {createContractOpen.value && (
        <CreateContract
          contractorId={contractor.id || draftId}
          onClose={onClose}
          refetch={() => navigate('/pages/agreements')}
          draft={draft}
          draftId={draftId}
          isDraftContractLoading={isDraftContractLoading}
        />
      )}
      {inviteContractorOpen && inviteContractorOpen.value && (
        <InviteContractorModal
          inviteContractorOpen={inviteContractorOpen}
          onFinish={onFinish}
          contractor={contractor}
          invitedContractor={invitedContractor}
          loading={loading}
          draftId={draftId}
          draft={draft}
          setDraftContractId={setDraftContractId}
          invitedContractId={invitedContractId}
        />
      )}
    </>
  )
}
