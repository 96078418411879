import Checkbox from '@atoms/Checkbox/Checkbox'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import ModalSide from '@atoms/ModalSide/ModalSide'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { AppContext, useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import {
  ADDITIONAL_PAYMENT_TYPE_KEY,
  calculateLastPayoutMonth,
  frequencyTypes,
  getFrequencyMessage,
  monthOptions,
  paymentTypes,
} from '@pages/additional-payments/utils'
import { employeeTableParse } from '@pages/employees/parse'
import { fetchContractors } from '@services/app.service'
import {
  calculateAdditionalPaymentEmployerCostTaxes,
  getCostCalculatorList,
  getPartnerCostCalc,
} from '@services/employer-cost.service'
import { postCalculateLastDay } from '@services/payroll.service'
import { isNumber } from 'lodash'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import { useDebounceValue } from 'usehooks-ts'

const StyledForm = styled.form`
  > * {
    margin-bottom: 16px;
  }
`
const formId = 'payment_create_form'

export default ({ isLoading, onHide, onCreate, currency, isEdit, selectedItem, isSuccess }) => {
  const { profile } = useContext(AppContext)
  const { currencies } = useApp()
  const [contracts, setContracts] = useState([])
  const currentDate = new Date(moment(new Date()).format('YYYY-MM-DD'))
  const [minDate, setMinDate] = useState(currentDate)
  const [apTypeKeys, setApTypeKeys] = useState()

  const {
    control,
    formState: { errors },
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      contract: selectedItem ? selectedItem.contract_data.id : null,
      ap_type_id: selectedItem?.ap_type || undefined,
      amount: selectedItem ? selectedItem.amount_in_company_currency : '',
      name: selectedItem ? selectedItem.title : '',
      description: selectedItem ? selectedItem.description : '',
      currency: selectedItem ? selectedItem.currency.id : currency?.id,
      frequency: selectedItem
        ? {
            value: selectedItem.recurrence_frequency,
            label: frequencyTypes.find((f) => f.value === selectedItem.recurrence_frequency)?.label,
          }
        : { value: 'ONE_TIME', label: 'One-time' },
      firstPayoutMonth: selectedItem
        ? {
            value: selectedItem.recurrence_starting_month,
            label: moment(selectedItem.recurrence_starting_month).format('MMMM YYYY'),
          }
        : null,
      hasEndOccurrences: selectedItem ? !!selectedItem.occurrences : false,
      endAfterOccurrences: selectedItem?.occurrences || '',
      tax: selectedItem?.tax,
    },
  })

  const isRecurrentWatch = watch('is_recurrent')
  const frequencyWatch = watch('frequency')
  const firstPayoutMonthWatch = watch('firstPayoutMonth')
  const hasEndOccurrencesWatch = watch('hasEndOccurrences')
  const endAfterOccurrencesWatch = watch('endAfterOccurrences')
  const employee = watch('contract')
  const type = watch('ap_type_id')
  const amount = watch('amount')
  const [debounceAmount, setDebounceAmount] = useDebounceValue(amount, 1000)

  const { isLoading: isLoadingContracts } = useQuery([fetchContractors.key, isRecurrentWatch], {
    queryFn: () =>
      fetchContractors.fetch({
        company_id: profile.id,
        contract_type: isRecurrentWatch === 'true' ? 'full_time_employee' : '',
        state: 'active',
        offset: 0,
        limit: 1000,
      }),
    onSuccess: ({ results }) => {
      setContracts(employeeTableParse(results))
      if (!selectedItem?.contract_data?.id) setValue('contract', null)
    },
  })

  const postCalculateLastDayMutate = useMutation('postCalculateLastDay', {
    mutationFn: (payload) => postCalculateLastDay(payload),
  })

  const addPmtListMutation = useMutation([getCostCalculatorList.key], {
    mutationFn: (partner) =>
      getCostCalculatorList.fetch({
        usage_area: 'additional_payment',
        limit: 100,
        offset: 0,
        country_id: employee?.countryId,
        partner,
        company: {
          user_id: profile.user.id,
        },
      }),
    onSuccess: (res) => {
      setApTypeKeys(
        res?.results.reduce((acc, value) => {
          return { ...acc, [value.id]: value }
        }, {})
      )
    },
  })

  const getPartnerCostCalcQuery = useQuery([getPartnerCostCalc.key, employee], {
    enabled: !!employee?.countryId && !!employee?.residencyType,
    queryFn: () =>
      getPartnerCostCalc.fetch({
        company: profile.id,
        residency_type: employee?.residencyType,
        is_test: profile.is_test,
        working_country: employee?.countryId,
      }),
    onSuccess: (res) => {
      addPmtListMutation.mutate(res)
    },
  })
  const calcAPQuery = useQuery(
    [calculateAdditionalPaymentEmployerCostTaxes.key, debounceAmount, type, employee?.countryId],
    {
      enabled: isNumber(type?.value || type) && !!employee?.countryId,
      keepPreviousData: true,
      queryFn: () =>
        calculateAdditionalPaymentEmployerCostTaxes.fetch({
          additional_payment_amount: debounceAmount || 0,
          additional_payment_type_id: type?.value || type,
          country: employee?.countryId,
        }),
    }
  )

  useEffect(() => {
    if (selectedItem?.contract_data?.id && contracts?.length && isEdit) {
      const contractValue = contracts.find((c) => c.id === selectedItem.contract_data.id)
      if (contractValue) {
        setValue('contract', {
          value: contractValue.id,
          contractor: contractValue.contract_type === 'contractor' ? ' (Contractor)' : '',
          label: contractValue.full_name,
          countryId: contractValue.working_country?.id,
          residencyType: contractValue.residency_type,
        })
      }
    }
  }, [selectedItem?.contract_data?.id, contracts, isEdit])

  useEffect(() => {
    if (selectedItem?.sub_ap_type && apTypeKeys) {
      const ap_type_id = Object.values(apTypeKeys).find((value) => value.name === selectedItem?.sub_ap_type)?.id
      if (ap_type_id) setValue('ap_type_id', ap_type_id)
    }
  }, [selectedItem?.sub_ap_type, apTypeKeys])
  const submit = (formValues) => {
    const category = apTypeKeys && apTypeKeys[formValues.ap_type_id?.value || formValues.ap_type_id]
    let body = {
      amount: formValues.amount,
      description: formValues.description,
      name: formValues.name,
      contract: formValues?.contract.value || formValues?.contract,
      currency: formValues.currency.value || formValues.currency,
      ap_type: category
        ? ADDITIONAL_PAYMENT_TYPE_KEY[category.additional_payment_category]
        : formValues.ap_type_id?.value || formValues.ap_type_id,
      sub_ap_type: category ? category.name : undefined,
      tax: calcAPQuery.data?.tax_amount || undefined,
      is_recurrent: formValues.frequency?.value !== 'ONE_TIME',
    }
    if (formValues.frequency?.value !== 'ONE_TIME') {
      Object.assign(body, {
        recurrence_frequency: formValues.frequency.value,
        recurrence_starting_month: formValues.firstPayoutMonth.value,
        ...(formValues.hasEndOccurrences &&
          formValues.endAfterOccurrences && {
            recurrence_ending_month: moment(formValues.firstPayoutMonth.value)
              .clone()
              .add(
                (formValues.endAfterOccurrences - 1) *
                  {
                    MONTHLY: 1,
                    EVERY_2_MONTHS: 2,
                    EVERY_3_MONTHS: 3,
                  }[formValues.frequency.value],
                'months'
              )
              .format('YYYY-MM-DD'),
          }),
      })
    }
    if (isEdit) {
      body = {
        ...body,
        tax: formValues?.tax,
      }
    }
    onCreate(body)
  }
  useEffect(() => {
    if (isSuccess) {
      reset()
    }
  }, [isSuccess])

  useEffect(() => {
    if (profile) {
      postCalculateLastDayMutate.mutate({
        country: profile?.legal_address?.living_country?.id,
        days: profile.additional_payment_cut_off_day,
      })
    }
  }, [profile])

  useEffect(() => {
    if (
      postCalculateLastDayMutate?.data?.last_day &&
      currentDate.getTime() >= new Date(postCalculateLastDayMutate?.data?.last_day).getTime()
    ) {
      const nextDate = new Date()
      nextDate.setMonth(currentDate.getMonth() + 1)
      nextDate.setDate(1)
      setMinDate(nextDate)
    }
  }, [postCalculateLastDayMutate.data])

  const options = useMemo(() => {
    return addPmtListMutation.data?.results.length
      ? addPmtListMutation.data.results.map((e) => ({ value: e.id, label: e.name }))
      : paymentTypes
  }, [addPmtListMutation.data])

  return (
    <ModalSide
      title={`${isEdit ? 'Edit' : 'Add'} additional payment`}
      onClose={onHide}
      onCancel={onHide}
      onOk={handleSubmit(submit)}
      okText="Save"
      cancelText="Close"
      okButtonProps={{
        type: 'submit',
        form: formId,
        size: 'small',
        disabled: isLoading,
        loading: isLoading,
        'data-testid': 'CreateModal-C431DF',
      }}
      cancelButtonProps={{
        priority: 'secondary',
        size: 'small',
        type: 'button',
        'data-testid': 'CreateModal-4EE017',
      }}
    >
      <StyledForm noValidate id={formId} className="d-flex flex-column" onSubmit={handleSubmit(submit)}>
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="contract"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="CreateModal-727DC9"
                  {...field}
                  isDisabled={isEdit || isLoadingContracts || isRecurrentWatch === ''}
                  isRequired
                  label={isLoadingContracts ? 'Loading...' : 'Select employee'}
                  options={contracts
                    .filter((contract) => contract.contractor_wage_type === 'monthly')
                    .map((contract) => ({
                      value: contract.id,
                      contractor: contract.contract_type === 'contractor' ? ' (Contractor)' : '',
                      label: contract.full_name,
                      countryId: contract.working_country?.id,
                      residencyType: contract.residency_type,
                    }))}
                  getOptionLabel={(option) => `${option.label}${option.contractor}`}
                />
              )
            }}
          />
          {errors.contract && <Typography className="text_regular__14 color_red">{errors.contract.message}</Typography>}
        </div>
        <div className="row">
          <div className="col">
            <Controller
              control={control}
              name="ap_type_id"
              rules={{ required: 'Field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="CreateModal-23478B"
                    {...field}
                    loading={addPmtListMutation.isLoading || getPartnerCostCalcQuery.isLoading}
                    isDisabled={isEdit || addPmtListMutation.isLoading || getPartnerCostCalcQuery.isLoading}
                    label="Payment type"
                    options={options}
                  />
                )
              }}
            />
            {errors.ap_type_id && (
              <Typography className="text_regular__14 color_red">{errors.ap_type_id.message}</Typography>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Controller
              control={control}
              name="currency"
              rules={{ required: 'Currency is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="CreateModal-8F01FE"
                    {...field}
                    label="Currency"
                    isDisabled={isEdit}
                    options={currencies.map((country) => ({
                      value: country.id,
                      label: country.name || country.short_code,
                    }))}
                  />
                )
              }}
            />
            {errors.currency && (
              <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Controller
              control={control}
              name="amount"
              rules={{
                required: 'Field is required',
                validate: {
                  minlength: (v) =>
                    /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) || 'Only 2 digits allowed after decimal point',
                },
              }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  disabled={isEdit}
                  required
                  label="Amount"
                  placeholder="$ 00.00"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )}
            />
            {errors.amount && <Typography className="text_regular__14 color_red">{errors.amount.message}</Typography>}
          </div>
        </div>
        {(calcAPQuery.data?.tax_amount && (
          <Input
            disabled
            value={isEdit ? selectedItem?.tax : calcAPQuery.data?.tax_amount}
            label="Employer tax & contributions"
            placeholder="Tax"
          />
        )) ||
          ''}
        {(calcAPQuery.data?.total && (
          <>
            <Input disabled value={calcAPQuery.data?.total} label="Total" placeholder="Tax" class />
            <Typography style={{ fontSize: '12px', color: '#8A96A1' }}>
              The estimated total amount, including employer taxes and contributions
            </Typography>
          </>
        )) ||
          ''}
        <div>
          <Controller
            control={control}
            name="frequency"
            rules={{ required: 'Field is required' }}
            render={({ field }) => (
              <>
                <Select {...field} label="Frequency" isRequired isDisabled={isEdit} options={frequencyTypes} />
                {field.value && (
                  <Typography style={{ fontSize: '12px', color: '#8A96A1' }}>
                    {getFrequencyMessage(field.value)}
                  </Typography>
                )}
              </>
            )}
          />
        </div>
        {!isEdit && frequencyWatch?.value !== 'ONE_TIME' && (
          <>
            <div>
              <Controller
                control={control}
                name="firstPayoutMonth"
                rules={{ required: 'Field is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="First expected payout month"
                    isRequired
                    isDisabled={isEdit}
                    options={monthOptions(minDate)}
                  />
                )}
              />
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox {...register('hasEndOccurrences')} label="End after" />
                  <Controller
                    control={control}
                    name="endAfterOccurrences"
                    rules={{
                      required: hasEndOccurrencesWatch ? 'Number of occurrences is required' : false,
                      min: {
                        value: 1,
                        message: 'Minimum 1 occurrence required',
                      },
                    }}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="number"
                        id="endAfterOccurrences"
                        disabled={!watch('hasEndOccurrences')}
                        min="1"
                        style={{
                          width: '50px',
                          marginLeft: '.5rem',
                          marginRight: '.5rem',
                        }}
                      />
                    )}
                  />
                  <span>occurrences</span>
                </div>
                {errors.endAfterOccurrences && (
                  <Typography
                    className="text_regular__14 color_red"
                    style={{
                      display: 'block',
                      marginLeft: '1.75rem',
                    }}
                  >
                    {errors.endAfterOccurrences.message}
                  </Typography>
                )}
                <span
                  style={{
                    display: 'block',
                    marginLeft: '1.75rem',
                    color: '#ADADAD',
                    fontSize: '12px',
                  }}
                >
                  {hasEndOccurrencesWatch && endAfterOccurrencesWatch && frequencyWatch && firstPayoutMonthWatch
                    ? `Last payout will be in ${calculateLastPayoutMonth(
                        firstPayoutMonthWatch,
                        frequencyWatch,
                        Number(endAfterOccurrencesWatch)
                      )}`
                    : 'Payment will be indefinite.'}
                </span>
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col-12">
            <Input
              data-testid="CreateModal-525C5C"
              {...register('name', {
                required: 'Field is required',
                maxLength: {
                  value: 50,
                  message: 'Title cannot exceed 50 characters',
                },
              })}
              isRequired
              label="Payment name"
              placeholder="Title"
            />
            {errors.name && <Typography className="text_regular__14 color_red">{errors.name.message}</Typography>}
          </div>
          <div className="col-12 mt-2">
            <TextArea
              data-testid="CreateModal-525C5C"
              {...register('description', {})}
              label="Description"
              placeholder="Title"
            />
            {errors.description && (
              <Typography className="text_regular__14 color_red">{errors.description.message}</Typography>
            )}
          </div>
        </div>
      </StyledForm>
    </ModalSide>
  )
}
