import './ExpenseStatus.scss'

import { capitalizeFirstLetter } from '@core/utils'

export default function ExpenseStatus({ status }) {
  if (!status) return <div className="status_expense">No Status</div>
  switch (status.toLowerCase()) {
    case 'created':
    case 'pending':
      return <div className="status_expense status_created_expense">Pending approval</div>
    case 'approved':
      return <div className="status_expense status_approved_expense">Approved</div>
    case 'approved_by_company':
    case 'awaiting_approval':
      return <div className="status_expense status_to_be_paid_next_month_expense">To be paid next month</div>
    case 'awaiting_payment':
      return <div className="status_expense status_to_be_paid_this_month_expense">To be paid this month</div>
    case 'invoiced':
      return <div className="status_expense status_invoiced_expense">Invoiced</div>
    case 'paid':
      return <div className="status_expense status_paid_expense">Paid</div>
    case 'deleted':
    case 'cancelled':
    case 'canceled':
      return <div className="status_expense status_canceled_expense">Cancelled</div>
    default:
      return <div className="status_ap status_default">{capitalizeFirstLetter(status).replace('_', ' ')}</div>
  }
}
