import Button from '@atoms/Button/Button'
import { OFFBOARDING_PTO_COMMUNICATION_METHOD, OFFBOARDING_PTO_COMMUNICATION_METHOD_MAP } from '@core/constants'

export const EmployeeOffboardingPtoSummary = ({ onEdit, data }) => {
  return (
    <table>
      <thead>
        <tr>
          <th width="50%">Paid time-off</th>
          <th>
            {onEdit && (
              <div className="d-flex justify-content-end">
                <Button size="xsmall" onClick={onEdit}>
                  Edit
                </Button>
              </div>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Time-off data source</td>
          <td>{OFFBOARDING_PTO_COMMUNICATION_METHOD_MAP[data?.pto_data_communication_method]}</td>
        </tr>
        {data?.pto_data_communication_method === OFFBOARDING_PTO_COMMUNICATION_METHOD.provide_pto_record && (
          <tr>
            <td>Uploaded time-off record</td>
            <td>
              {data?.employee_time_off_records?.map(({ filename, file }) => (
                <div key={filename}>
                  <a href={file} rel="noreferrer" target="_blank">
                    {filename}
                  </a>
                </div>
              ))}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
