import { Api, ApiCostCalculator, ApiNoResInterceptor } from '@api/Api'
import { generateService } from '@core/utils'

export const calculateTaxes = generateService(async (body) => {
  const { data } = await ApiNoResInterceptor.post(`employer-cost/calculate-taxes/`, body)
  return data
})

export const calculateAdditionalPaymentEmployerCostTaxes = generateService(async (params) => {
  const { data } = await Api.get(`additional-payments/tax-calculation/`, { params })
  return data
})

export const getCostCalculatorList = generateService(async (payload) => {
  const { data } = await ApiCostCalculator.post(`/employer-cost/tax-types/`, payload)
  return data
})

export const getPartnerCostCalc = generateService(async (params) => {
  const { data } = await Api.get(`/partners/choose-partner-by-parameters/`, { params })
  return data
})
