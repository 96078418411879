import Button from '@atoms/Button/Button'
import {
  EMPLOYEE_NOTICE_PERIOD_ARRANGEMENT_MAP,
  EMPLOYEE_OFFBOARDING_LEAVE_TYPES,
  OFFBOARDING_RESIGNATION_REASONS_MAP,
  OFFBOARDING_TERMINATION_REASONS_MAP,
  OFFBOARDING_TYPE,
  OFFBOARDING_TYPE_MAP,
} from '@core/constants'
import { formatDate } from '@core/utils'

export const EmployeeOffboardingRequestSummary = ({ data, onEdit }) => {
  const offboardingReasonMap =
    data?.offboarding_type === OFFBOARDING_TYPE.Termination
      ? OFFBOARDING_TERMINATION_REASONS_MAP
      : OFFBOARDING_RESIGNATION_REASONS_MAP

  return (
    <table>
      <thead>
        <tr>
          <th width="50%">Offboarding request</th>
          <th>
            {onEdit && (
              <div className="d-flex justify-content-end">
                <Button size="xsmall" onClick={onEdit}>
                  Edit
                </Button>
              </div>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Type</td>
          <td>{OFFBOARDING_TYPE_MAP[data?.offboarding_type]}</td>
        </tr>
        <tr>
          <td>Reason</td>
          <td>{offboardingReasonMap[data?.reason]?.label}</td>
        </tr>
        <tr>
          <td>Employee informed</td>
          <td>{data?.employee_informed ? 'Yes' : 'No'}</td>
        </tr>
        <tr>
          <td>Consent for RemoFirst to contact employee for offboarding purpose</td>
          <td>{data?.consent_for_contact ? 'Yes' : 'No'}</td>
        </tr>
        <tr>
          <td>Requested last working day</td>
          <td>{formatDate(data?.requested_last_working_day)} (our team will confirm)</td>
        </tr>
        <tr>
          <td>Employee currently on leave</td>
          <td>{data?.employee_on_leave ? 'Yes' : 'No'}</td>
        </tr>
        {data?.employee_on_leave && (
          <>
            <tr>
              <td>Leave type</td>
              <td>{EMPLOYEE_OFFBOARDING_LEAVE_TYPES[data?.employee_leave_type]}</td>
            </tr>
            <tr>
              <td>Leave dates</td>
              <td>
                {formatDate(data?.employee_leave_start_date)} - {formatDate(data?.employee_leave_end_date)}
              </td>
            </tr>
          </>
        )}
        <tr>
          <td>Notice period arrangement</td>
          <td>{EMPLOYEE_NOTICE_PERIOD_ARRANGEMENT_MAP[data?.notice_period_arrangement]}</td>
        </tr>
        <tr>
          <td>Support document(s)</td>
          <td>
            {data?.support_documents?.map(({ filename, file }) => (
              <div key={filename}>
                <a href={file} rel="noreferrer" target="_blank">
                  {filename}
                </a>
              </div>
            ))}
          </td>
        </tr>
      </tbody>
    </table>
  )
}
