import Button from '@atoms/Button/Button'
import RadioButton from '@atoms/RadioButton/RadioButton'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { HIRING_PLAN_REMOFIRST, HIRING_PLAN_REMOFIRST_MAP } from '@core/constants'
import { Controller, useForm } from 'react-hook-form'

export const EmployeeOffboardingHiringForm = ({ onSubmit }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = useForm({
    defaultValues: {},
  })

  const watchHiringPlan = watch('hiringPlan')

  const onBeforeSubmit = ({ hiringPlan, hiringPlanNote }) => {
    const payload = {
      hiring_plan: hiringPlan,
    }
    if (hiringPlanNote) {
      payload.hiring_plan_note = hiringPlanNote
    }
    onSubmit(payload)
  }

  return (
    <form onSubmit={handleSubmit(onBeforeSubmit)} className="d-flex flex-column h-100 remo-form">
      <section className="flex-grow-1 d-flex flex-column align-items-center">
        <div className="remo-form-input">
          <Controller
            control={control}
            name="hiringPlan"
            rules={{ required: 'Choose one option' }}
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Are you planning to hire with RemoFirst?"
                options={[
                  {
                    id: HIRING_PLAN_REMOFIRST.next_3_months,
                    text: HIRING_PLAN_REMOFIRST_MAP.next_3_months,
                    value: HIRING_PLAN_REMOFIRST.next_3_months,
                  },
                  {
                    id: HIRING_PLAN_REMOFIRST.three_to_six_months,
                    text: HIRING_PLAN_REMOFIRST_MAP.three_to_six_months,
                    value: HIRING_PLAN_REMOFIRST.three_to_six_months,
                  },
                  {
                    id: HIRING_PLAN_REMOFIRST.six_months_or_later,
                    text: HIRING_PLAN_REMOFIRST_MAP.six_months_or_later,
                    value: HIRING_PLAN_REMOFIRST.six_months_or_later,
                  },
                  {
                    id: HIRING_PLAN_REMOFIRST.no_plan,
                    text: HIRING_PLAN_REMOFIRST_MAP.no_plan,
                    value: HIRING_PLAN_REMOFIRST.no_plan,
                  },
                ]}
                layout="vertical"
                isRequired
              />
            )}
          />
          {errors.hiringPlan && (
            <Typography className="text_regular__14 color_red">{errors.hiringPlan.message}</Typography>
          )}
        </div>

        {watchHiringPlan === HIRING_PLAN_REMOFIRST.no_plan && (
          <div className="remo-form-input">
            <TextArea
              label="Please explain the main reason behind this decision:"
              {...register('hiringPlanNote', {
                required: 'Hiring plan note is required',
              })}
              rows={4}
              maxLength={500}
              placeholder="We value your feedback and are committed to improving our service by listening to your insights."
              isRequired
            />
            {errors.hiringPlanNote && (
              <Typography className="text_regular__14 color_red">{errors.hiringPlanNote.message}</Typography>
            )}
          </div>
        )}

        <div className="align-self-center mt-5 mb-5">
          <Button type="submit" className="align-self-end">
            Next
          </Button>
        </div>
      </section>
    </form>
  )
}
