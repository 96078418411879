import Checkbox from '@atoms/Checkbox/Checkbox'
import DateInput from '@atoms/DateInput/DateInput'
import EditModal from '@atoms/EditModal/EditModal'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import { Tooltip } from '@atoms/Tooltip/tooltip.component'
import Typography from '@atoms/Typography/Typography'
import { formatMinutesToHours, setCurrencyChangeEvent } from '@core/utils'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { v4 } from 'uuid'

const PaymentType = {
  CurrentRate: 0,
  AnyAmount: 1,
}

export default function RateModal({
  closeModal,
  onSave,
  values,
  currency,
  contractWageType,
  loading,
  readOnly,
  notificationText,
}) {
  const [editState, setEditState] = React.useState({})
  const [total, setTotal] = React.useState()
  const [tabId, setTabId] = useState(PaymentType.CurrentRate)
  const [errors, setErrors] = useState({})

  const onChangeEdit = (evt) => {
    const { value } = evt.target
    setEditState((prevState) => ({
      ...prevState,
      [evt.target.name]: value,
    }))
  }
  const onChangeSaveRate = (evt) => {
    const { checked } = evt.target
    setEditState((prevState) => ({
      ...prevState,
      [evt.target.name]: checked,
    }))
  }
  const onSaveClick = useCallback(() => {
    setErrors({})
    if (editState.end_date && editState.start_date && moment(editState.end_date).isBefore(editState.start_date)) {
      setErrors({ end_date: 'End date cannot be earlier than start date' })
      return
    }
    const data = {
      ...editState,
      start_date: moment(editState.start_date).format('YYYY-MM-DD'),
      end_date: moment(editState.end_date).format('YYYY-MM-DD'),
    }
    if (tabId === PaymentType.CurrentRate) {
      data.payment_amount = null
    } else {
      data.rate = null
      data.save_rate_in_contract = null
    }
    onSave(data)
  }, [editState])

  useEffect(() => {
    setEditState({
      rate: values?.rate_num,
      ...(contractWageType === 'hourly' && {
        worked_hours: values?.worked_hours_num,
      }),
      payment_amount: values?.payment_amount || '0',
      recalculate_total: true,
      start_date: values?.start_date ? new Date(values?.start_date) : '',
      end_date: values?.end_date ? new Date(values?.end_date) : '',
      save_rate_in_contract: values?.save_rate_in_contract || false,
      timesheet_aggregated_minutes: values?.timesheet_aggregated_minutes,
    })
  }, [values, contractWageType])

  const onChangeStartDate = (value) => {
    setEditState((prevState) => ({
      ...prevState,
      start_date: value,
    }))
  }
  const onChangeEndDate = (value) => {
    setErrors((prev) => ({ ...prev, end_date: null }))
    setEditState((prevState) => ({
      ...prevState,
      end_date: value,
    }))
  }

  useEffect(() => {
    if (editState.worked_hours || editState.rate) {
      setTotal(editState.worked_hours * editState.rate)
    }
  }, [editState.worked_hours, editState.rate])

  const renderTabCurrentRate = () => {
    return (
      <>
        <div className="remo-form-input">
          <Input
            data-testid="RateModal-1F0D52"
            label="Contractor"
            disabled
            value={values.contractor}
            addText="This contractor is on a {Pay as you go} plan."
          />
        </div>
        <div className="remo-form-input">
          <CurrencyInput
            label="Rate"
            name="rate"
            disabled={loading || readOnly}
            currency={currency}
            value={editState?.rate}
            onChange={setCurrencyChangeEvent(onChangeEdit)}
          />
        </div>
        <div className="toggle-bg">
          <Checkbox
            data-testid="RateModal-3D4678"
            name="save_rate_in_contract"
            label="Save new rate in the contract"
            checked={editState.save_rate_in_contract}
            disabled={readOnly}
            value={editState.save_rate_in_contract}
            onChange={onChangeSaveRate}
          />
        </div>
        <div className="row mt-3">
          <div className="remo-form-input col-6">
            <DateInput
              className="mb-3"
              onChange={onChangeStartDate}
              label="Start date"
              value={editState?.start_date}
              minDate={values.contract_start_date && new Date(values.contract_start_date)}
              maxDate={values.contract_end_date && new Date(values.contract_end_date)}
              disabled={readOnly}
            />
          </div>
          <div className="remo-form-input col-6">
            <DateInput
              onChange={onChangeEndDate}
              label="End date"
              value={editState?.end_date}
              minDate={values.contract_start_date && new Date(values.contract_start_date) && editState.start_date}
              maxDate={values.contract_end_date && new Date(values.contract_end_date)}
              disabled={readOnly}
            />
            {errors.end_date && <Typography className="text_regular__14 color_red">{errors.end_date}</Typography>}
          </div>
          {!readOnly && (
            <div className="remo-form-input col-12">
              <NotificationBlock text="Selected dates must fall within the original contract agreement's start and end dates" />
            </div>
          )}
          {contractWageType === 'hourly' && (
            <div className="remo-form-input">
              <Input
                data-testid="RateModal-C6FDE5"
                onChange={onChangeEdit}
                label="Hours worked"
                type="number"
                disabled={loading || readOnly}
                name="worked_hours"
                value={
                  editState?.timesheet_aggregated_minutes
                    ? formatMinutesToHours(editState?.timesheet_aggregated_minutes)
                    : editState?.worked_hours
                }
              />
            </div>
          )}

          {contractWageType === 'hourly' && (
            <div className="remo-form-input">
              <Input
                data-testid="RateModal-C5A5F7"
                label="Total amount payable"
                type="number"
                disabled
                value={total}
                tooltip={
                  <Tooltip
                    id={v4()}
                    content="The total amount payable is calculated by multiplying rate and number of hours."
                  />
                }
              />
            </div>
          )}
          {values.description && (
            <div className="toggle-bg">
              <Typography className="text_medium__14">Description</Typography>
              <Typography className="text_regular__14 mt-2">{values.description}</Typography>
            </div>
          )}
        </div>
      </>
    )
  }

  const renderTabAnyAmount = () => {
    return (
      <>
        <div className="remo-form-input">
          <Input
            data-testid="RateModal-CD4603"
            label="Contractor"
            disabled
            value={values.contractor}
            addText="This contractor is on a {Fixed rate} plan."
          />
        </div>
        <div className="remo-form-input">
          <CurrencyInput
            label="Enter any amount"
            name="payment_amount"
            disabled={loading || readOnly}
            currency={currency}
            value={editState?.payment_amount}
            onChange={setCurrencyChangeEvent(onChangeEdit)}
          />
        </div>
        <div className="row mt-3">
          <div className="remo-form-input col-6">
            <DateInput
              className="mb-3"
              onChange={onChangeStartDate}
              label="Start date"
              value={editState?.start_date}
              minDate={values.contract_start_date && new Date(values.contract_start_date)}
              maxDate={values.contract_end_date && new Date(values.contract_end_date)}
              disabled={readOnly}
            />
          </div>
          <div className="remo-form-input col-6">
            <DateInput
              onChange={onChangeEndDate}
              label="End date"
              value={editState?.end_date}
              minDate={values.contract_start_date && new Date(values.contract_start_date)}
              maxDate={values.contract_end_date && new Date(values.contract_end_date)}
              disabled={readOnly}
            />
          </div>
          {!readOnly && (
            <div className="remo-form-input col-12">
              <NotificationBlock text="Selected dates must fall within the original contract agreement's start and end dates" />
            </div>
          )}

          {values.description && (
            <div className="toggle-bg">
              <Typography className="text_medium__14">Description</Typography>
              <Typography className="text_regular__14 mt-2">{values.description}</Typography>
            </div>
          )}
        </div>
      </>
    )
  }

  const onTabChange = (tab) => {
    setTabId(tab)
    setEditState({
      ...editState,
      recalculate_total: tab === PaymentType.CurrentRate,
    })
  }

  return (
    <EditModal
      visible
      footer
      loading={loading}
      title={contractWageType !== 'hourly' ? 'Payment' : 'Hours/rate'}
      onSave={onSaveClick}
      closeModal={closeModal}
      isOkButtonDisabled={readOnly}
      bodyStyle={notificationText ? { paddingTop: '0px' } : undefined}
    >
      {notificationText && <NotificationBlock text={notificationText} isError />}
      <Tabs selectedTab={tabId} onTabChange={onTabChange}>
        <Tab tabId={PaymentType.CurrentRate} title="Pay current rate">
          {renderTabCurrentRate(tabId)}
        </Tab>
        <Tab tabId={PaymentType.AnyAmount} title="Pay any amount">
          {renderTabAnyAmount(tabId)}
        </Tab>
      </Tabs>
    </EditModal>
  )
}
