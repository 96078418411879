import Step from '@atoms/Stepper/Step'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import Typography from '@atoms/Typography/Typography'
import { KOMPASSIFY_SURVEYS } from '@core/constants'
import { runKompassifySurvey } from '@core/utils'
import ContractorSuccessModal from '@pages/employees/contractor-success-modal'
import { getContractorDetails } from '@services/contract.service'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { ContractForm } from './contract-form/contract-form'
import BasicInformation from './form/BasicInformationForm'
import CompensationForm from './form/CompensationForm'
import JobDetailsForm from './form/JobDetailsForm'
import { MilestonesForm } from './form/Milestones'

const step1 = 'Personal details'
const step2 = 'Job details'
const step3 = 'Compensation'
const step4 = 'Milestones'
const step5 = 'Contract'

export default ({
  onMassImport,
  loading,
  onClose,
  isContinuedContract,
  onSave,
  addedNewEmployee,
  onCloseContractModal,
  draftId,
  invitedContractId,
  invitationPayload,
}) => {
  const [activeStep, setStep] = useState(1)
  const updateContractOpen = useBoolean(false)
  const inviteContractorOpen = useBoolean(false)
  const [hasMilestones, setHasMilestones] = useState(false)
  const isLastStep = (hasMilestones && activeStep === 5) || (!hasMilestones && activeStep === 4)

  const [payload, setPayload] = useState({
    contract_type: 'contractor',
  })

  const { data: contractor, isLoading: isDraftContractLoading } = useQuery('draftContractor', {
    queryFn: async () => {
      const result = await getContractorDetails(draftId || invitedContractId)
      return result
    },
    enabled: !!draftId || !!invitedContractId,
  })

  const handleBack = () => {
    setStep((prev) => prev - 1)
  }

  const handleNext = (formValues) => {
    const isPreLastStep = (hasMilestones && activeStep === 4) || (!hasMilestones && activeStep === 3)

    if (isPreLastStep) {
      inviteContractorOpen.setTrue()
    } else {
      inviteContractorOpen.setFalse()
    }

    if (formValues) {
      setPayload((prev) => ({
        ...prev,
        ...formValues,
      }))
      setStep((prev) => Number(prev) + 1)
    }
  }

  const handleFinish = (formValues) => {
    setPayload((prev) => {
      const body = { ...prev, ...formValues }
      onSave(body)
      return body
    })
  }

  return (
    <Modal show onClose={onClose} fullscreen>
      <Modal.Body className="p-0">
        <Stepper
          activeStep={activeStep}
          title="Add a contractor"
          description={
            <>
              Please fill in the details of the contractor you are inviting. <br />
              <Typography className="mt-4">
                Have a large number of contractors to add? Try{' '}
                <span role="button" aria-hidden style={{ color: '#40b84c' }} onClick={onMassImport}>
                  Mass Import
                </span>
              </Typography>
            </>
          }
        >
          <Step step="1" title={step1} setStep={setStep} disabled={isLastStep}>
            <StepContent title={step1} onClose={onClose}>
              {!isLastStep && (
                <BasicInformation
                  onNext={handleNext}
                  onClose={onClose}
                  draft={contractor || invitationPayload}
                  draftId={draftId || invitationPayload?.id}
                  isDraftContractLoading={isDraftContractLoading}
                  initValue={payload}
                />
              )}
            </StepContent>
          </Step>

          <Step step="2" title={step2} setStep={setStep} disabled={isLastStep}>
            <StepContent title={step2} onClose={onClose} onBack={handleBack}>
              {!isLastStep && (
                <JobDetailsForm
                  onNext={handleNext}
                  onClose={onClose}
                  draft={contractor || invitationPayload}
                  draftId={draftId || invitationPayload?.id}
                  isDraftContractLoading={isDraftContractLoading}
                  initValue={payload}
                />
              )}
            </StepContent>
          </Step>

          <Step step="3" title={step3} setStep={setStep} disabled={isLastStep}>
            <StepContent title={step3} onClose={onClose} onBack={handleBack}>
              {!isLastStep && (
                <CompensationForm
                  loading={loading}
                  initValue={payload}
                  onNext={handleNext}
                  workingCountryId={payload?.working_country}
                  setHasMilestones={setHasMilestones}
                  hasMilestones={hasMilestones}
                  onClose={onClose}
                  draft={contractor || invitationPayload}
                  draftId={draftId || invitationPayload?.id}
                  isDraftContractLoading={isDraftContractLoading}
                />
              )}
            </StepContent>
          </Step>

          {hasMilestones && (
            <Step step="4" title={step4} setStep={setStep} disabled={isLastStep}>
              <StepContent title={step4} onClose={onClose} onBack={handleBack}>
                {!isLastStep && (
                  <MilestonesForm
                    onNext={handleNext}
                    onClose={onClose}
                    initValue={payload}
                    loading={loading}
                    draft={contractor || invitationPayload}
                    draftId={draftId || invitationPayload?.id}
                    isDraftContractLoading={isDraftContractLoading}
                  />
                )}
              </StepContent>
            </Step>
          )}

          <Step step={hasMilestones ? '5' : '4'} title={step5} setStep={setStep}>
            <StepContent title={step5} onClose={onClose}>
              <ContractForm
                updateContractOpen={updateContractOpen}
                inviteContractorOpen={inviteContractorOpen}
                contractor={addedNewEmployee || payload}
                invitedContractor={payload}
                onClose={onClose}
                loading={loading}
                onFinish={handleFinish}
                draft={contractor || invitationPayload}
                draftId={draftId || invitationPayload?.id}
                invitedContractId={invitedContractId}
                setPayload={setPayload}
                isDraftContractLoading={isDraftContractLoading}
              />
            </StepContent>
          </Step>
        </Stepper>
      </Modal.Body>

      {isContinuedContract && (
        <ContractorSuccessModal
          onClose={() => {
            runKompassifySurvey(KOMPASSIFY_SURVEYS.ContractorAdded)
            onCloseContractModal()
            onClose()
          }}
          onSave={() => {
            runKompassifySurvey(KOMPASSIFY_SURVEYS.ContractorAdded)
            handleNext()
            onCloseContractModal()
          }}
        />
      )}
    </Modal>
  )
}
