import Button from '@atoms/Button/Button'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import RadioButton from '@atoms/RadioButton/RadioButton'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { InfoMessage } from '@components/info-message/info-message.component'
import { PROPERTY_RETURN_INSTRUCTION } from '@core/constants'
import { getCurrency } from '@core/utils'
import { add } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

export const EmployeeOffboardingFinalPaymentForm = ({ onSubmit, employee }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    register,
  } = useForm({
    defaultValues: {},
  })

  const watchIsEquipmentReturnNeeded = watch('isEquipmentReturnNeeded')

  const onBeforeSubmit = ({
    additionalCompensation,
    isEquipmentReturnNeeded,
    isShippingExpensesReimbursed,
    instructionsProvided,
    additionalNote,
  }) => {
    const payload = {
      additional_compensation: additionalCompensation ?? 0,
      employee_holds_equipment: isEquipmentReturnNeeded === 'true',
    }
    if (isEquipmentReturnNeeded === 'true') {
      payload.shipping_expenses_reimbursed = isShippingExpensesReimbursed === 'true'
      payload.property_return_instruction = instructionsProvided
    }
    if (additionalNote) {
      payload.additional_note = additionalNote
    }
    onSubmit(payload)
  }

  return (
    <form onSubmit={handleSubmit(onBeforeSubmit)} className="d-flex flex-column h-100 remo-form">
      <section className="flex-grow-1 d-flex flex-column align-items-center">
        <InfoMessage
          type="warning"
          message={
            <span>
              Please ensure that all additional payments and expenses have been logged on the RemoFirst Platform. To add
              items, please visit the{' '}
              <Link to="/pages/additional-payments">
                <strong>Additional payments</strong>
              </Link>{' '}
              and{' '}
              <Link to="/pages/expense">
                <strong>Expenses</strong>
              </Link>{' '}
              sections. <br /> RemoFirst will provide the final payment invoice, taking into consideration the
              information below, along with statutory payments, which will be communicated by our team.
            </span>
          }
        />
        <div className="remo-form-input">
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="additionalCompensation"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  placeholder="0.00"
                  label="Kindly indicate the amount of any additional compensation you would like to provide to the employee; if none, leave it blank."
                  currency={getCurrency(employee?.compensation.currency)}
                />
              )}
            />
            {errors.additionalCompensation && (
              <Typography className="text_regular__14 color_red">{errors.additionalCompensation.message}</Typography>
            )}
          </div>
          <Controller
            control={control}
            name="isEquipmentReturnNeeded"
            rules={{ required: 'Choose one option' }}
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Does the employee need to return work equipment?"
                addText="RemoFirst is not responsible for the return of equipment, nor can we withhold any payments to the employee for this."
                options={[
                  {
                    id: 'isEquipmentReturnNeeded_true',
                    text: 'Yes',
                    value: 'true',
                  },
                  {
                    id: 'isEquipmentReturnNeeded_false',
                    text: 'No',
                    value: 'false',
                  },
                ]}
                layout="vertical"
                isRequired
              />
            )}
          />
          {errors.isEquipmentReturnNeeded && (
            <Typography className="text_regular__14 color_red">{errors.isEquipmentReturnNeeded.message}</Typography>
          )}
        </div>

        {watchIsEquipmentReturnNeeded === 'true' && (
          <>
            <div className="remo-form-input">
              <Controller
                control={control}
                name="isShippingExpensesReimbursed"
                rules={{ required: 'Choose one option' }}
                render={({ field }) => (
                  <RadioButton
                    {...field}
                    label="Will the employee be reimbursed for shipping expenses?"
                    options={[
                      {
                        id: 'isShippingExpensesReimbursed_true',
                        text: 'Yes',
                        value: 'true',
                      },
                      {
                        id: 'isShippingExpensesReimbursed_false',
                        text: 'No',
                        value: 'false',
                      },
                    ]}
                    layout="vertical"
                    isRequired
                  />
                )}
              />
              {errors.isShippingExpensesReimbursed && (
                <Typography className="text_regular__14 color_red">
                  {errors.isShippingExpensesReimbursed.message}
                </Typography>
              )}
            </div>
            <div className="remo-form-input">
              <Controller
                control={control}
                name="instructionsProvided"
                rules={{ required: 'Choose one option' }}
                render={({ field }) => (
                  <RadioButton
                    {...field}
                    label="Has the employee been provided with instructions for the return of company property?"
                    options={[
                      {
                        id: PROPERTY_RETURN_INSTRUCTION.yes,
                        text: 'Yes',
                        value: PROPERTY_RETURN_INSTRUCTION.yes,
                      },
                      {
                        id: PROPERTY_RETURN_INSTRUCTION.not_yet,
                        text: 'Not yet. The employee will be provided with instructions upon confirmation of the notice.',
                        value: PROPERTY_RETURN_INSTRUCTION.not_yet,
                      },
                      {
                        id: PROPERTY_RETURN_INSTRUCTION.not_applicable,
                        text: 'Not applicable',
                        value: PROPERTY_RETURN_INSTRUCTION.not_applicable,
                      },
                    ]}
                    layout="vertical"
                    isRequired
                  />
                )}
              />
              {errors.instructionsProvided && (
                <Typography className="text_regular__14 color_red">{errors.instructionsProvided.message}</Typography>
              )}
            </div>
          </>
        )}

        <div className="remo-form-input">
          <TextArea
            label="Additional note"
            {...register('additionalNote')}
            rows={4}
            maxLength={500}
            placeholder="Add any relevant information that you think our team should know."
          />
        </div>

        <div className="align-self-center mt-5 mb-5">
          <Button type="submit" className="align-self-end">
            Next
          </Button>
        </div>
      </section>
    </form>
  )
}
