import Avatar from '@atoms/Avatar/Avatar'
import Button from '@atoms/Button/Button'
import { EditModalForm } from '@atoms/EditModal/EditModalForm'
import Icon from '@atoms/Icon/Icon'
import ModalSide from '@atoms/ModalSide/ModalSide'
import NotFound from '@atoms/NotFound/NotFound'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { DataCard } from '@components/data-card/data-card.component'
import { Loader } from '@components/loader/loader.component'
import {
  BALANCE_BREAKDOWN_TYPES,
  BALANCE_BREAKDOWN_TYPES_MAP,
  TIME_OFF_STATUSES,
  TIME_OFF_STATUSES_MAP,
  USER_ROLES_MAP,
} from '@core/constants'
import { useToast } from '@core/hooks/useNotification'
import { documentIcon, noteIcon } from '@core/icons/icons'
import { formatDate } from '@core/utils'
import { createAdHoc, getTimeOffBalanceBreakdown } from '@services/time-off.service'
import { useState } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import { TimeOffBalancesAdHocForm } from '../time-off-balances-ad-hoc-form/time-off-balances-ad-hoc-form.component'
import { Styled } from './time-off-balance-breakdown.styles'

const StyledCarryoverBalanceParagraph = styled.p`
  font-size: 12px;
  color: #e0613a;
`

const BalanceBreakdownAction = ({ item, balance }) => {
  let status = ''
  let date = ''

  if (item.bb_type === BALANCE_BREAKDOWN_TYPES.VACATION) {
    status = TIME_OFF_STATUSES_MAP[item.absence?.status]
    if (item.absence?.status === TIME_OFF_STATUSES.APPROVED) {
      date = formatDate(item.absence?.events?.find((event) => event.status === item.absence?.status)?.modified_at)
    } else {
      date = formatDate(item.absence?.end_date)
    }
  } else if (item.bb_type === BALANCE_BREAKDOWN_TYPES.ADHOC || item.bb_type === BALANCE_BREAKDOWN_TYPES.CARRYOVER) {
    status = `Added by ${USER_ROLES_MAP[item?.created_by?.role_name]}`
    date = formatDate(item?.created_at)
  } else {
    status = 'Autogenerated'
    date = formatDate(balance.contract?.working_country?.vacation_year_end)
  }

  return (
    <>
      <Typography className="text_medium__14">{status}</Typography>
      <Typography className="text_light__14 color_grey">on {date}</Typography>
    </>
  )
}

const getFields = (balance, absence) => {
  return [
    {
      title: 'Type',
      type: 'custom',
      render: ({ bb_type }) => <span>{BALANCE_BREAKDOWN_TYPES_MAP[bb_type]}</span>,
    },
    {
      title: 'Days',
      type: 'custom',
      render: ({ bb_type, number_of_days, contract }) => (
        <Styled.DaysCell>
          <Styled.ColoredNumber value={number_of_days}>{number_of_days}</Styled.ColoredNumber>
          <Typography className="text_light__14 color_grey">
            {bb_type === BALANCE_BREAKDOWN_TYPES.VACATION && (
              <>
                {absence?.status === 'APPROVED' && (
                  <StyledCarryoverBalanceParagraph>
                    -{absence?.total_days}{' '}
                    {`(carryover -${absence?.carryover_days_used}; regular -${absence?.regular_days_used})`}
                  </StyledCarryoverBalanceParagraph>
                )}
                {`${formatDate(absence?.start_date)} -
                  ${formatDate(absence?.end_date)}`}
              </>
            )}
          </Typography>
        </Styled.DaysCell>
      ),
    },
    {
      title: 'Note/doc',
      type: 'custom',
      render: ({ attached_document, comment }) => (
        <div>
          {attached_document && (
            <OverlayTrigger overlay={<Tooltip>Supporting document available</Tooltip>}>
              <Styled.IconButton type="button" onClick={() => window.open(attached_document, '_blank')}>
                <Icon icon={documentIcon} />
              </Styled.IconButton>
            </OverlayTrigger>
          )}
          {comment && (
            <OverlayTrigger overlay={<Tooltip>{comment}</Tooltip>}>
              <span>
                <Icon icon={noteIcon} />
              </span>
            </OverlayTrigger>
          )}
        </div>
      ),
    },
    {
      title: 'Action',
      type: 'custom',
      render: (item) => <BalanceBreakdownAction item={item} balance={balance} />,
    },
  ]
}

export const TimeOffBalanceBreakdown = ({ item, absence, onClose }) => {
  const [calendarYear, setCalendarYear] = useState(null)
  const adHocAdjustmentOpenState = useBoolean(false)
  const { successAlert } = useToast()
  const queryClient = useQueryClient()

  const { isFetching, data: balanceBreakdownData } = useQuery(getTimeOffBalanceBreakdown.key, {
    queryFn: () =>
      getTimeOffBalanceBreakdown.fetch(item.contract?.id, {
        start_date: item.contract?.working_country?.vacation_year_start,
        end_date: item.contract?.working_country?.vacation_year_end,
        id: item.contract?.id,
      }),
    keepPreviousData: true,
  })

  const { mutate, isLoading } = useMutation({
    mutationFn: (payload) => createAdHoc.fetch(item.contract?.id, payload),
    onSuccess: () => {
      adHocAdjustmentOpenState.setFalse()
      successAlert('Ad-hoc has been created!')
      queryClient.refetchQueries(getTimeOffBalanceBreakdown.key)
    },
  })

  const handleAdHocCreate = (data) => {
    if (!data) return
    const payload = {
      contract_id: item.contract?.id,
      number_of_days: +data.days,
      note: data.note ?? '',
      attached_document: data.documents ? data.documents[0] : null,
    }
    mutate(payload)
  }

  return (
    <>
      <ModalSide
        title="Balance breakdown"
        onClose={onClose}
        footer={false}
        secondaryActions={[
          <Button priority="secondary" size="small" onClick={onClose} key={1}>
            Close
          </Button>,
        ]}
      >
        <Styled.Container>
          <Styled.Employee>
            <Avatar className="my-auto" src="" width={48} height={48} />
            <Styled.EmployeeInfo>
              <Typography className="text_medium__14">{item.contract?.user_info?.full_name}</Typography>
              {/* <Typography className="text_regular__14 color_black_light">Financial Controller・Full-time</Typography> */}
              <Typography className="text_light__14 color_grey">{item.contract?.working_country?.name}</Typography>
            </Styled.EmployeeInfo>
            <DataCard
              title="Vacation balance"
              value={item.available_days}
              detail={`until ${formatDate(item.contract?.working_country?.vacation_year_end)}`}
            />
            <div>
              <Typography>View time-off policy for:</Typography>
              <Link to={`/pages/team/${item.contract.id}/9`} className="link">
                <Typography className="semibold__16 color_secondary_light">Employee</Typography>
              </Link>
              <Link to="/pages/profile?tab=3" className="link">
                <Typography className="semibold__16 color_secondary_light">Company</Typography>
              </Link>
            </div>
          </Styled.Employee>
          <Styled.Actions>
            <Button size="small" type="button" onClick={adHocAdjustmentOpenState.setTrue}>
              + Add ad-hoc adjustment
            </Button>
          </Styled.Actions>
          <Styled.Filters>
            <Typography className="text_regular__14">Vacation calendar year:</Typography>
            <Typography className="text_medium__14">
              {formatDate(item.contract?.working_country?.vacation_year_start)} to{' '}
              {formatDate(item.contract?.working_country?.vacation_year_end)}
            </Typography>
            {/* <div style={{ width: 190 }}>
              <Select
                placeholder="Select..."
                value={calendarYear}
                onChange={({ value }) => setCalendarYear(value)}
                options={[
                  {
                    label: `${formatDate(item.contract?.working_country?.vacation_year_start)} to ${formatDate(item?.contract?.working_country?.vacation_year_end)}`,
                    value: '1',
                  },
                ]}
              />
            </div> */}
          </Styled.Filters>
          {isFetching ? (
            <Loader />
          ) : (
            <div>
              {balanceBreakdownData?.count > 0 ? (
                <CustomTable
                  fields={getFields(item, absence)}
                  total={balanceBreakdownData?.count}
                  data={balanceBreakdownData?.results}
                />
              ) : (
                <NotFound title="It's quiet in here ..." description="There are no entries " />
              )}
            </div>
          )}
        </Styled.Container>
      </ModalSide>
      <EditModalForm
        show={adHocAdjustmentOpenState.value}
        title="Ad-hoc adjustment"
        loading={isLoading}
        onHide={adHocAdjustmentOpenState.setFalse}
        okText="Add"
      >
        <TimeOffBalancesAdHocForm onSubmit={handleAdHocCreate} />
      </EditModalForm>
    </>
  )
}
