import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { Attachment } from '@components/attachment/attachment.component'
import { AttachmentUpload } from '@components/attachment/attachment-upload.component'
import { CheckboxGroup } from '@components/checkbox-group/checkbox-group.component'
import { InfoMessage } from '@components/info-message/info-message.component'
import {
  commonDocumentMimeTypes,
  EMPLOYEE_NOTICE_PERIOD_ARRANGEMENT,
  EMPLOYEE_OFFBOARDING_LEAVE_TYPES,
  EMPLOYEE_PERFORMANCE_REASON_BASED_ACTIONS_MAP,
  OFFBOARDING_TERMINATION_REASONS,
  OFFBOARDING_TYPE,
} from '@core/constants'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { getGroupedReasons, getOffboardingReasonsMap, parsePayload } from './employee-offboarding-request-form.utils'

const StyledLeaveDatesWrapper = styled.div`
  display: flex;
  gap: 1rem;

  .react-datepicker-wrapper {
    width: 100%;
  }
`

export const EmployeeOffboardingRequestForm = ({ onSubmit }) => {
  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    watch,
    register,
    reset,
  } = useForm({
    defaultValues: {
      comment: '',
    },
  })

  const watchOffboardingType = watch('offboardingType')
  const watchReason = watch('reason')
  const watchCompletedActions = watch('completedActions')
  const watchIsOnLeave = watch('isOnLeave')

  const onBeforeSubmit = (data) => {
    const payload = parsePayload(data)
    onSubmit(payload)
  }

  console.log(
    Object.entries(getGroupedReasons(watchOffboardingType)).map(([key, value]) => ({
      label: key,
      options: value,
    }))
  )

  return (
    <form onSubmit={handleSubmit(onBeforeSubmit)} className="d-flex flex-column h-100 remo-form">
      <section className="flex-grow-1 d-flex flex-column align-items-center">
        <InfoMessage
          message="RemoFirst requires 2–3 business days to validate and process an offboarding request."
          type="info"
          fullWidth
        />
        <div className="remo-form-input">
          <Controller
            control={control}
            name="offboardingType"
            rules={{ required: 'Choose one option' }}
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Offboarding type"
                options={[
                  {
                    id: 'termination',
                    text: 'Termination - company initiated',
                    value: OFFBOARDING_TYPE.Termination,
                  },
                  {
                    id: 'resignation',
                    text: 'Resignation - employee initiated',
                    value: OFFBOARDING_TYPE.Resignation,
                  },
                ]}
                layout="vertical"
                isRequired
              />
            )}
          />
          {errors.offboardingType && (
            <Typography className="text_regular__14 color_red">{errors.offboardingType.message}</Typography>
          )}
        </div>

        {watchOffboardingType && (
          <div className="remo-form-input">
            <Controller
              control={control}
              name="reason"
              rules={{ required: 'Choose one option' }}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    label="Reason"
                    isRequired
                    options={Object.entries(getGroupedReasons(watchOffboardingType)).map(([key, value]) => ({
                      label: key,
                      options: value,
                    }))}
                    onChange={({ value }) => field.onChange(value)}
                    grouped
                  />
                )
              }}
            />
            {errors.reason && <Typography className="text_regular__14 color_red">{errors.reason.message}</Typography>}
          </div>
        )}

        {watchReason === OFFBOARDING_TERMINATION_REASONS.performance_issues && (
          <div className="remo-form-input">
            <CheckboxGroup
              control={control}
              label="Please check off any of the following actions you have completed."
              name="completedActions"
              options={Object.entries(EMPLOYEE_PERFORMANCE_REASON_BASED_ACTIONS_MAP).map(([key, value]) => ({
                label: value,
                value: key,
              }))}
              row
            />
          </div>
        )}

        {watchReason === OFFBOARDING_TERMINATION_REASONS.transfer_to_another_eor && (
          <div className="remo-form-input">
            <TextArea
              label="Please explain the main reason for the transfer:"
              {...register('transferToAnotherEorReason', {
                required: 'Explanation is required',
              })}
              rows={4}
              maxLength={500}
              placeholder="We value your feedback and are committed to improving our service by listening to your insights."
            />
          </div>
        )}

        {watchOffboardingType === OFFBOARDING_TYPE.Resignation && (
          <div className="remo-form-input">
            <Controller
              control={control}
              name="noticeDate"
              rules={{ required: 'Notice given on date is required' }}
              render={({ field }) => {
                return (
                  <DateInput
                    {...field}
                    label="Notice given on"
                    addText="The date on which the employee submits the notice"
                    isRequired
                  />
                )
              }}
            />
            {errors.noticeDate && (
              <Typography className="text_regular__14 color_red">{errors.noticeDate.message}</Typography>
            )}
          </div>
        )}

        {watchOffboardingType === OFFBOARDING_TYPE.Termination && (
          <div className="remo-form-input">
            <Controller
              control={control}
              name="isEmployeeInformed"
              rules={{ required: 'Choose one option' }}
              render={({ field }) => (
                <RadioButton
                  {...field}
                  label="Has the employee already been informed of the termination?"
                  addText="We strongly recommend refraining from notifying the employee until we align on all details to ensure compliance with local legislation and avoid increased termination costs."
                  options={[
                    {
                      id: 'isEmployeeInformed_true',
                      text: 'Yes',
                      value: 'true',
                    },
                    {
                      id: 'isEmployeeInformed_false',
                      text: 'No',
                      value: 'false',
                    },
                  ]}
                  layout="vertical"
                  isRequired
                />
              )}
            />
            {errors.isEmployeeInformed && (
              <Typography className="text_regular__14 color_red">{errors.isEmployeeInformed.message}</Typography>
            )}
          </div>
        )}

        <div className="remo-form-input">
          <Controller
            control={control}
            name="isConsentToContactGiven"
            rules={{ required: 'Choose one option' }}
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Do you give RemoFirst consent to contact the employee for offboarding purposes?"
                options={[
                  {
                    id: 'isConsentToContactGiven_true',
                    text: 'Yes',
                    value: 'true',
                  },
                  {
                    id: 'isConsentToContactGiven_false',
                    text: 'No',
                    value: 'false',
                  },
                ]}
                layout="vertical"
                isRequired
              />
            )}
          />
          {errors.isConsentToContactGiven && (
            <Typography className="text_regular__14 color_red">{errors.isConsentToContactGiven.message}</Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Controller
            control={control}
            name="requestedLastWorkingDay"
            rules={{ required: 'Requested last working day is required' }}
            render={({ field }) => {
              return (
                <DateInput
                  {...field}
                  label="Requested last working day"
                  addText="This is a tentative last working day; the official date will be confirmed by our offboarding team. Retroactive termination is not possible, and the employee must be paid until the official last day."
                  isRequired
                />
              )
            }}
          />
          {errors.requestedLastWorkingDay && (
            <Typography className="text_regular__14 color_red">{errors.requestedLastWorkingDay.message}</Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Controller
            control={control}
            name="isOnLeave"
            rules={{ required: 'Choose one option' }}
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Is the employee currently on leave?"
                addText="The employer is prohibited from offboarding an employee while they are on leave (parental, vacation, sick leave, pregnancy, etc.)."
                options={[
                  {
                    id: 'isOnLeave_false',
                    text: 'No, the employee is not on leave',
                    value: 'false',
                  },
                  {
                    id: 'isOnLeave_true',
                    text: 'Yes, the employee is on leave',
                    value: 'true',
                  },
                ]}
                layout="vertical"
                isRequired
              />
            )}
          />
          {errors.isOnLeave && (
            <Typography className="text_regular__14 color_red">{errors.isOnLeave.message}</Typography>
          )}
        </div>

        {watchIsOnLeave === 'true' && (
          <div className="remo-form-input">
            <div className="remo-form-input">
              <Controller
                control={control}
                name="leaveType"
                rules={{ required: 'Choose one option' }}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      label="Leave type"
                      options={Object.keys(EMPLOYEE_OFFBOARDING_LEAVE_TYPES).map((key) => ({
                        value: key,
                        label: EMPLOYEE_OFFBOARDING_LEAVE_TYPES[key],
                      }))}
                      onChange={({ value }) => field.onChange(value)}
                      isRequired
                    />
                  )
                }}
              />
              {errors?.reason && (
                <Typography className="text_regular__14 color_red">{errors.reason.message}</Typography>
              )}
            </div>
            <StyledLeaveDatesWrapper>
              <div className="remo-form-input">
                <Controller
                  control={control}
                  name="leaveStartDate"
                  rules={{ required: 'Leave start date is required' }}
                  render={({ field }) => {
                    return <DateInput {...field} label="Leave start date" />
                  }}
                />
                {errors.leaveStartDate && (
                  <Typography className="text_regular__14 color_red">{errors.leaveStartDate.message}</Typography>
                )}
              </div>
              <div className="remo-form-input">
                <Controller
                  control={control}
                  name="leaveEndDate"
                  rules={{ required: 'Leave end date is required' }}
                  render={({ field }) => {
                    return <DateInput {...field} label="Leave end date" />
                  }}
                />
                {errors.leaveEndDate && (
                  <Typography className="text_regular__14 color_red">{errors.leaveEndDate.message}</Typography>
                )}
              </div>
            </StyledLeaveDatesWrapper>
          </div>
        )}

        <div className="remo-form-input">
          <Controller
            control={control}
            name="noticePeriodArrangement"
            rules={{ required: 'Choose one option' }}
            render={({ field }) => (
              <RadioButton
                {...field}
                label="If permitted by local legislation, would you prefer the employee to work their notice period or receive payment in lieu?"
                options={[
                  {
                    id: EMPLOYEE_NOTICE_PERIOD_ARRANGEMENT.worked,
                    text: 'Notice should be worked',
                    value: EMPLOYEE_NOTICE_PERIOD_ARRANGEMENT.worked,
                  },
                  {
                    id: EMPLOYEE_NOTICE_PERIOD_ARRANGEMENT.payment_in_lieu,
                    text: 'Notice should be paid in lieu (garden leave)',
                    value: EMPLOYEE_NOTICE_PERIOD_ARRANGEMENT.payment_in_lieu,
                  },
                ]}
                layout="vertical"
                isRequired
              />
            )}
          />
          {errors.noticePeriodArrangement && (
            <Typography className="text_regular__14 color_red">{errors.noticePeriodArrangement.message}</Typography>
          )}
        </div>

        <div className="remo-form-input">
          <Controller
            control={control}
            name="supportingDocuments"
            rules={{
              required: {
                value: watchOffboardingType === OFFBOARDING_TYPE.Resignation,
                message: 'Supporting document is required',
              },
            }}
            render={({ field }) => (
              <AttachmentUpload
                label="Supporting document(s)"
                addText="You must provide a supporting document, such as a performance review, to justify the termination"
                helperText="Text, word, pdf, image files up to 50 MB"
                accept={commonDocumentMimeTypes}
                onAttach={field.onChange}
                onDelete={(data) => console.log(data)}
                multiple
                detailedFiles
                isRequired
              />
            )}
          />
          {errors.supportingDocuments && (
            <Typography className="text_regular__14 color_red">{errors.supportingDocuments.message}</Typography>
          )}
        </div>

        <div className="align-self-center mt-5 mb-5">
          <Button type="submit" className="align-self-end">
            Next
          </Button>
        </div>
      </section>
    </form>
  )
}
