import CustomTable from '@atoms/Table/CustomTable'
import usePagination from '@core/hooks/usePagination'
import { getAllTimesheets } from '@services/timesheets.service'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { getTimesheetFields } from '../mock'

export const AllTimesheets = () => {
  const navigate = useNavigate()
  const params = useParams()

  const { page, limit, setPage } = usePagination({ page: 1, limit: 5 })

  const { data, isFetching, isLoading } = useQuery([getAllTimesheets.key, page, limit], {
    queryFn: () =>
      getAllTimesheets.fetch({
        limit,
        offset: (page - 1) * limit,
        contract_id: params.id,
      }),
    enabled: Boolean(params.id),
  })

  const handleView = (id) => {
    navigate(`timesheets-details/${id}`)
  }

  return (
    <CustomTable
      fields={getTimesheetFields(handleView)}
      data={data?.results || []}
      onPage={setPage}
      loading={isFetching || isLoading}
      page={page}
      total={data?.count || 0}
      pageSize={limit}
    />
  )
}
