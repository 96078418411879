import Step from '@atoms/Stepper/Step'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import { EmployeeOffboardingFinalPaymentForm } from '@features/employee-offboarding/employee-offboarding-final-payment-form/employee-offboarding-final-payment-form.component'
import { EmployeeOffboardingHiringForm } from '@features/employee-offboarding/employee-offboarding-hiring-form/employee-offboarding-hiring-form.component'
import { EmployeeOffboardingPtoForm } from '@features/employee-offboarding/employee-offboarding-pto-form/employee-offboarding-pto-form.component'
import { EmployeeOffboardingRequestForm } from '@features/employee-offboarding/employee-offboarding-request-form/employee-offboarding-request-form.component'
import { EmployeeOffboardingSummary } from '@features/employee-offboarding/employee-offboarding-summary/employee-offboarding-summary.component'
import { useState } from 'react'

const stepConfig = {
  offboardingRequest: {
    name: 'Offboarding request',
    order: 1,
  },
  pto: {
    name: 'Paid time-off',
    order: 2,
  },
  finalPayment: {
    name: 'Final payment',
    order: 3,
  },
  hiringPlan: {
    name: 'Hiring plan',
    order: 4,
  },
  summary: {
    name: 'Summary',
    order: 5,
  },
}

export const EmployeeOffboardingModal = ({ open, onClose, onSubmit, isLoading, employee }) => {
  const [activeStep, setActiveStep] = useState(1)
  const [payload, setPayload] = useState(null)

  const handleNext = (data) => {
    if (!payload) {
      setPayload(data)
    } else {
      setPayload((prev) => ({ ...prev, ...data }))
    }
    setActiveStep((prev) => prev + 1)
  }

  const handleSubmit = () => {
    const payloadWithFileIds = {
      ...payload,
      support_documents: payload.support_documents?.map(({ file_uuid }) => file_uuid),
      employee_time_off_records: payload.employee_time_off_records?.map(({ file_uuid }) => file_uuid),
    }
    onSubmit(payloadWithFileIds)
  }

  return (
    // TODO(bakbergen): render steps using stepConfig
    <Stepper
      activeStep={activeStep}
      title="Offboard employee"
      description="RemoFirst will make sure the offboarding process is as smooth as possible for both you and your employee."
      sidebarStyle={{ width: '360px' }}
    >
      <Step title={stepConfig.offboardingRequest.name} setStep={setActiveStep}>
        <StepContent title={stepConfig.offboardingRequest.name} onClose={onClose}>
          <EmployeeOffboardingRequestForm onSubmit={handleNext} />
        </StepContent>
      </Step>
      <Step title={stepConfig.pto.name} setStep={setActiveStep}>
        <StepContent title={stepConfig.pto.name} onClose={onClose}>
          <EmployeeOffboardingPtoForm onSubmit={handleNext} employee={employee} />
        </StepContent>
      </Step>
      <Step title={stepConfig.finalPayment.name} setStep={setActiveStep}>
        <StepContent title={stepConfig.finalPayment.name} onClose={onClose}>
          <EmployeeOffboardingFinalPaymentForm onSubmit={handleNext} employee={employee} />
        </StepContent>
      </Step>
      <Step title={stepConfig.hiringPlan.name} setStep={setActiveStep}>
        <StepContent title={stepConfig.hiringPlan.name} onClose={onClose}>
          <EmployeeOffboardingHiringForm onSubmit={handleNext} />
        </StepContent>
      </Step>
      <Step title={stepConfig.summary.name} setStep={setActiveStep}>
        <StepContent title={stepConfig.summary.name} onClose={onClose}>
          <EmployeeOffboardingSummary
            onSubmit={handleSubmit}
            stepConfig={stepConfig}
            navigateStep={setActiveStep}
            data={payload}
            isLoading={isLoading}
          />
        </StepContent>
      </Step>
    </Stepper>
  )
}
