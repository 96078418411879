import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { Dropdown } from '@components/dropdown/dropdown.component'
import { useApp } from '@core/context'
import { useDirectManagers } from '@core/hooks/useDirectManagers'
import { fetchContractors } from '@services/app.service'
import {
  createDraftContractForContractor,
  getJobDetailsList,
  updateDraftContractForContractor,
} from '@services/contract.service'
import { DotWave } from '@uiball/loaders'
import { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

const HintText = styled.span`
  font-size: 12px;
  color: #8a96a1;
  margin-top: 4px;
`

export default ({ initValue, onNext, onClose, draft, draftId, isDraftContractLoading }) => {
  const dropdownRef = useRef(null)
  const { profile } = useApp()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const {
    watch,
    register,
    reset,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      position: '',
      department: '',
      position_description: '',
      line_manager: '',
    },
  })
  const jobTitle = watch('position')
  const dropdownOpenState = useBoolean(false)

  const createDraftContract = useMutation({
    mutationFn: (payload) => createDraftContractForContractor(payload),
  })

  const updateDraftContract = useMutation({
    mutationFn: (payload) => updateDraftContractForContractor(draftId, payload),
  })

  const jobDetailsListQuery = useQuery({
    queryKey: [getJobDetailsList.key],
    queryFn: getJobDetailsList.fetch,
  })

  const { directManagers, isLoading } = useDirectManagers(profile.id)

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      dropdownOpenState.setFalse()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (initValue) {
      const lineManager = directManagers?.find(
        (manager) => manager.id === (draft?.direct_manager?.id || initValue?.direct_manager?.id)
      )
      reset({
        position: initValue.job?.position,
        position_description: initValue.job?.position_description,
        department: initValue?.department,
        line_manager: {
          value: lineManager ? lineManager.id : '',
          label: lineManager ? lineManager.name : '',
        },
      })
    }
  }, [directManagers, draft?.direct_manager, initValue, reset])

  useEffect(() => {
    if (draft) {
      const lineManager = directManagers?.find(
        (manager) => manager.id === (draft?.direct_manager?.id || initValue?.direct_manager?.id)
      )
      reset({
        position: draft.job?.position,
        position_description: draft.job?.position_description,
        department: draft?.department,
        line_manager: {
          value: lineManager ? lineManager.id : '',
          label: lineManager ? lineManager.name : '',
        },
      })
    }
  }, [directManagers, draft, reset])

  const submit = ({ position, position_description, department, line_manager }) => {
    onNext({
      job: {
        position: position?.label || position,
        position_description,
      },
      department,
      direct_manager: line_manager?.value || '',
    })
  }

  const handleSaveDraft = (formValues) => {
    if (isValid) {
      const payload = {
        ...(draft?.contractor_draft_contract_raw_data || initValue),
        job: {
          ...(draft?.contractor_draft_contract_raw_data?.job || initValue?.job),
          position: formValues.position,
          position_description: formValues.position_description,
        },
        department: formValues.department,
        direct_manager: formValues.line_manager?.value || '',
        company: profile.id,
        working_country: draft?.contractor_draft_contract_raw_data?.working_country || initValue?.working_country,
      }
      if (draft?.contractor_draft_contract_raw_data || initValue?.contractor_draft_contract_raw_data) {
        updateDraftContract.mutate({ ...payload, raw_data: payload }) // BE needs this aproach
      } else {
        createDraftContract.mutate({ ...payload, raw_data: payload }) // BE needs this aproach
      }
      queryClient.refetchQueries(fetchContractors.key)
      onClose()
      navigate('/pages/team')
    }
  }

  if (isDraftContractLoading) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={32} speed={1} color="black" />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="d-flex flex-column h-100 employees-page__form">
      <section className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 remo-form-input" ref={dropdownRef}>
          <Dropdown
            open={dropdownOpenState.value}
            trigger={
              <Input
                data-testid="JobDetailsForm-1EA106"
                {...register('position', {
                  required: 'Job title is required',
                  maxLength: {
                    value: 128,
                    message: 'Job title cannot exceed 128 characters.',
                  },
                  validate: (value) => value.trim() !== '' || 'Job title cannot be empty or just spaces',
                })}
                label="Job title"
                type="text"
                isRequired
                onFocus={dropdownOpenState.setTrue}
                placeholder="Enter job title"
              />
            }
            menu={
              (jobDetailsListQuery?.data?.results.length &&
                (jobTitle
                  ? // eslint-disable-next-line no-unsafe-optional-chaining
                    jobDetailsListQuery.data.results?.filter((f) =>
                      f.name?.toLowerCase().includes(jobTitle.toLowerCase())
                    )
                  : // eslint-disable-next-line no-unsafe-optional-chaining
                    jobDetailsListQuery.data.results || []
                ).map(({ id, name, scope_of_work }) => (
                  <button
                    data-testid="JobDetailsForm-D82E90"
                    key={id}
                    type="button"
                    onClick={() => {
                      dropdownOpenState.setFalse()
                      setValue('position', name)
                      setValue('position_description', scope_of_work)
                    }}
                  >
                    {name}
                  </button>
                ))) ||
              []
            }
          />
          {errors.position && <Typography className="text_regular__14 color_red">{errors.position.message}</Typography>}
        </div>
        <div className="w-100 remo-form-input">
          <TextArea
            {...register('position_description')}
            type="text"
            rows={10}
            label="Description of work"
            maxLength={2000}
            placeholder="This will be pre-filled with a Remofirst template of the core duties and responsibilities of the role based on the job title provided. "
          />
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="JobDetailsForm-F2986B"
            {...register('department')}
            type="text"
            label="Department"
            placeholder="Enter department"
          />
        </div>
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="line_manager"
            render={({ field }) => {
              return (
                <>
                  <Select
                    {...field}
                    label="Line manager"
                    placeholder="Select"
                    options={
                      directManagers?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      })) || []
                    }
                    loading={isLoading}
                    isClearable
                  />
                  <HintText>
                    The line manager will receive all notifications related to this Contractor. This can be added later
                    from the contractor’s profile.
                  </HintText>
                </>
              )
            }}
          />
          {errors.line_manager && (
            <Typography className="text_regular__14 color_red">{errors.line_manager.message}</Typography>
          )}
        </div>
        <div className="mt-5 pb-5 d-flex gap-4 align-self-center">
          {/* {(!draft || (draft && draft.state === 'draft')) && (
            <Button
              priority="secondary"
              onClick={handleSubmit(handleSaveDraft)}
              className="align-self-end"
              disabled={!isValid}
            >
              Save draft and exit
            </Button>
          )} */}
          <Button data-testid="JobDetailsForm-29DE62" type="submit" className="align-self-end">
            Save and continue
          </Button>
        </div>
      </section>
    </form>
  )
}
