import Button from '@atoms/Button/Button'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import { ADDITIONAL_PAYMENT_TYPE_KEY } from '@pages/additional-payments/utils'
import { getCostCalculatorList } from '@services/employer-cost.service'
import React, { useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import styled from 'styled-components'

const paymentTypes = [
  { value: 'BONUS', label: 'Bonus' },
  { value: 'COMMISSION', label: 'Commission' },
  { value: 'RETRO', label: 'Retro' },
  { value: 'OVERTIME', label: 'Overtime' },
  { value: 'DEDUCTION', label: 'Deduction' },
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'OTHER', label: 'Other' },
]

const StyledForm = styled.form`
  > * {
    margin-bottom: 16px;
  }
`
const formId = 'payment_create_form'

export default ({ data, isLoading, onHide, onCreate }) => {
  const { currencies, profile } = useApp()
  const [apTypeKeys, setApTypeKeys] = useState()

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ap_type: '',
      amount: '',
      name: '',
      description: '',
      currency: '',
    },
  })

  const addPmtListQuery = useQuery(
    [getCostCalculatorList.key, data?.country, data?.partner],
    () =>
      getCostCalculatorList.fetch({
        usage_area: 'additional_payment',
        limit: 100,
        offset: 0,
        country_id: data.country?.id,
        partner: data?.partner
          ? {
              name: data.partner.name,
              user_id: data.partner.id,
              id: data.partner.partner_profile_id || 0,
            }
          : undefined,
        company: {
          user_id: profile.user.id,
        },
      }),
    {
      enabled: !!data?.country && !!data?.partner,
      onSuccess: (res) => {
        setApTypeKeys(
          res?.results.reduce((acc, value) => {
            return { ...acc, [value.id]: value }
          }, {})
        )
      },
    }
  )

  const submit = (formValues) => {
    const category = apTypeKeys && apTypeKeys[formValues.ap_type_id?.value || formValues.ap_type_id]
    onCreate({
      ...formValues,
      currency: formValues.currency.value,
      ap_type: category
        ? ADDITIONAL_PAYMENT_TYPE_KEY[category.additional_payment_category]
        : formValues.ap_type_id?.value || formValues.ap_type_id,
      sub_ap_type: category ? category.name : undefined,
    })
  }

  const options = useMemo(() => {
    return addPmtListQuery.data?.results.length
      ? addPmtListQuery.data.results.map((e) => ({ value: e.id, label: e.name }))
      : paymentTypes
  }, [addPmtListQuery.data])

  return (
    <Modal show onHide={onHide} centered>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">New Additional Payment</Typography>
      </Modal.Header>
      <Modal.Body>
        <StyledForm noValidate id={formId} className="d-flex flex-column" onSubmit={handleSubmit(submit)}>
          <div className="row">
            <div className="col">
              <Controller
                control={control}
                name="ap_type_id"
                rules={{ required: 'Field is required' }}
                render={({ field }) => {
                  return <Select data-testid="CreateModal-948398" {...field} label="Payment type" options={options} />
                }}
              />
              {errors.ap_type_id && (
                <Typography className="text_regular__14 color_red">{errors.ap_type_id.message}</Typography>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Controller
                control={control}
                name="amount"
                rules={{
                  required: 'Field is required',
                  validate: {
                    minlength: (v) =>
                      /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) || 'Only 2 digits allowed after decimal point',
                  },
                }}
                render={({ field }) => (
                  <CurrencyInput
                    {...field}
                    label="Amount"
                    placeholder="Enter the adjustment amount"
                    onChange={setCurrencyChangeEvent(field.onChange)}
                  />
                )}
              />
              {errors.amount && <Typography className="text_regular__14 color_red">{errors.amount.message}</Typography>}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Controller
                control={control}
                name="currency"
                rules={{ required: 'Currency is required' }}
                render={({ field }) => {
                  return (
                    <Select
                      data-testid="CreateModal-BC82FA"
                      {...field}
                      label="Currency"
                      options={currencies.map((country) => ({
                        value: country.id,
                        label: country.name || country.short_code,
                      }))}
                    />
                  )
                }}
              />
              {errors.currency && (
                <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Input
                data-testid="CreateModal-9ACE7F"
                {...register('name', { required: 'Field is required' })}
                label="Reason name"
                placeholder="Title"
              />
              {errors.name && <Typography className="text_regular__14 color_red">{errors.name.message}</Typography>}
            </div>
            <div className="col-12 mt-2">
              <TextArea placeholder="Description..." {...register('description')} />
              {errors.description && (
                <Typography className="text_regular__14 color_red">{errors.description.message}</Typography>
              )}
            </div>
          </div>
        </StyledForm>
      </Modal.Body>
      <Modal.Footer>
        <Button data-testid="CreateModal-640D79" priority="secondary" size="small" type="button" onClick={onHide}>
          Close
        </Button>
        <Button
          data-testid="CreateModal-567718"
          type="submit"
          form={formId}
          size="small"
          disabled={isLoading}
          loading={isLoading}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
