import './PayContractorsPage.scss'

import { useAxiosCall } from '@api/Api'
import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import Modal from '@atoms/Modal/Modal'
import NotFound from '@atoms/NotFound/NotFound'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { PayContractorInfoButton } from '@components/pay-contractor-info-button'
import { EMPLOYEE_STATES, KOMPASSIFY_SURVEYS } from '@core/constants'
import { AppContext } from '@core/context'
import usePagination from '@core/hooks/usePagination'
import { clearMedium, searchIcon } from '@core/icons/icons'
import * as icons from '@core/icons/icons'
import { runKompassifySurvey, SplitNumber } from '@core/utils'
import RateModal from '@pages/payContracts/RateModal'
import { DotWave } from '@uiball/loaders'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { HiDownload } from 'react-icons/hi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useBoolean, useDebounceValue } from 'usehooks-ts'

import {
  fetchContractorPayments,
  fetchDeleteContractorPayments,
  paymentInvoicePreview,
} from '../../services/payments.service'
import CreateInvoiceConfirmModal from './CreateInvoiceConfirmModal'
import DownloadReport from './DownloadReport'
import { InvoiceCreateErrorsModal } from './InvoiceCreateErrorsModal'
import NewPaymentModal from './NewPaymentModal'
import { payContractorsTableParse } from './parse'
import { getFields } from './payContractorsMock'

export default function PayContractorsPage() {
  const queryClient = useQueryClient()

  const { profile } = useContext(AppContext)
  const { page, limit, setPage } = usePagination({ page: 1, limit: 10 })
  const [successAlert, setSuccessAlert] = useState(false)
  const [invoiceCreateErrors, setInvoiceCreateErrors] = useState(null)
  const [rateValues, setRateValues] = useState()
  const [transactionId, setTransactionId] = useState()
  const [currency, setCurrency] = useState()
  const [contractWageType, setContractWageType] = useState()
  const [total, setTotal] = useState()
  const [editModal, setEditModal] = useState(false)
  const [isEditModalReadOnly, setEditModalReadOnly] = useState(false)
  const [editModalKey, setEditModalKey] = useState(false)
  const isOpenDownloadCsv = useBoolean(false)
  const [contractIds, setContractIds] = useState([])
  const [currentEditId, setCurrentEditId] = useState()
  const [debouncedValue, setDebouncedValue] = useDebounceValue(contractIds, 1000)
  const [selectedPayContractDelete, setSelectedPayContractDelete] = useState(null)
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useDebounceValue(search, 500)
  const isCreateInvoiceConfirmListOpen = useBoolean(false)
  const createInvoiceLabel =
    contractIds.length > 1
      ? `Create invoices for ${contractIds.length} contractors`
      : `Create an invoice for ${contractIds.length} contractor`
  const paymentPluralLabel = contractIds.length > 1 ? 'These payments' : 'This payment'

  const { value: visibleDelete, toggle: toggleDelete } = useBoolean(false)
  const newPaymentModalState = useBoolean(false)
  const navigate = useNavigate()

  const { data, refetch, isFetching } = useQuery(['active-contractor-payments', debouncedSearch], {
    queryFn: () => fetchContractorPayments(profile.id, 1, debouncedSearch, 100000),
  })

  const isEmpty = Boolean(data?.length === 0)

  const activePayments = useMemo(() => {
    if (!data?.results.length) {
      return []
    }
    const readyToBePaidPayments = payContractorsTableParse(
      data.results.filter((item) => item.contract_onboarding_status !== EMPLOYEE_STATES.ONBOARDING)
    )

    return readyToBePaidPayments
  }, [data])

  const allPayments = useMemo(() => {
    if (!data?.results.length) {
      return []
    }
    const res = payContractorsTableParse(data.results)

    return res
  }, [data])

  const paymentsListToConfirm = allPayments.filter((i) => contractIds.includes(i.id))
  const paginatedPayments = useMemo(() => {
    const startIndex = (page - 1) * limit
    const endIndex = startIndex + limit
    const paginatedLocalPayments = allPayments.slice(startIndex, endIndex)
    return paginatedLocalPayments
  }, [allPayments, page, limit])

  const deleteMutate = useMutation({
    mutationFn: (contractorId) => fetchDeleteContractorPayments(contractorId),
    onSuccess: () => {
      queryClient.setQueryData(['active-contractor-payments', debouncedSearch], (oldData) => {
        if (!oldData) return oldData
        const updated = {
          ...oldData,
          count: oldData.count - 1,
          results: oldData.results.filter((payment) => payment.id !== selectedPayContractDelete),
        }
        return updated
      })
      setContractIds((prevIds) => prevIds.filter((id) => id !== selectedPayContractDelete))
      toggleDelete()
      toast.success('Contractor payment successfully deleted')
    },
  })

  const [{ loading: invoiceLoading }, postCreateInvoice] = useAxiosCall(
    {
      method: 'POST',
      url: `contractor-payments/create-invoice/`,
    },
    { manual: true }
  )

  const postPreviewInvoice = useMutation(paymentInvoicePreview, {
    onSuccess: (response) => {
      const file = new Blob([response], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL, '_blank')
    },
  })
  const [{ loading: totalLoading }, postCalculateTotal] = useAxiosCall(
    {
      method: 'POST',
      url: `contractor-payments/calculate-totals/`,
    },
    { manual: true }
  )
  const [{ loading: contractPaymentLoading }, patchContractPayments] = useAxiosCall(
    {
      method: 'put',
      url: `contractor-payments/${currentEditId}/update/`,
    },
    { manual: true }
  )

  const onSaveRate = useCallback(
    (rateData) => {
      const body = { ...rateData }
      if (!body.rate) delete body.rate
      if (!body.payment_amount) delete body.payment_amount
      if (!body.save_rate_in_contract) delete body.save_rate_in_contract
      patchContractPayments({
        data: body,
      })
        .then(() => {
          toast.success('Successfully updated')
          refetch()
          setEditModal(false)
        })
        .catch(() => {
          setEditModal(false)
        })
    },
    [currentEditId]
  )

  useEffect(() => {
    if (contractIds.length) {
      setCurrency(contractIds[0]?.currency)
    }
  }, [contractIds])
  const closeSuccessAlert = useCallback(() => {
    runKompassifySurvey(KOMPASSIFY_SURVEYS.InvoiceCreated)
    setSuccessAlert(false)
  }, [])

  const onCreateInvoice = useCallback(() => {
    postCreateInvoice({ data: { contractor_payments: contractIds } }).then((res) => {
      setTotal(undefined)
      isCreateInvoiceConfirmListOpen.setFalse()
      setContractIds([])

      if (res.data.errors) {
        setInvoiceCreateErrors(res.data.errors.items)
      } else {
        refetch()
        setTransactionId(res.data.transaction_id)
        setSuccessAlert(true)
      }
    })
  }, [contractIds])

  const handlePaymentConfirmList = () => {
    isCreateInvoiceConfirmListOpen.setTrue()
  }

  const onPreviewInvoice = useCallback(() => {
    postPreviewInvoice.mutate({ contractor_payments: contractIds })
  }, [contractIds])

  const onClickEdit = useCallback((rowData) => {
    console.log('rowData', rowData)
    const isTimesheetBasedPayment = rowData.row.original.description?.includes('Timesheet')
    setEditModalReadOnly(!!isTimesheetBasedPayment)
    setEditModal(true)
    setCurrentEditId(rowData.row.original.id)
    setCurrency(rowData.row.original.currency)
    setContractWageType(rowData.row.original.contractor_wage_type)
    switch (rowData.key) {
      case 'worked_hours':
        setRateValues({
          worked_hours_num: rowData.row.original.worked_hours_num,
          rate_num: rowData.row.original.rate_num,
          start_date: rowData.row.original?.start_date || '',
          end_date: rowData.row.original?.end_date || '',
          payment_amount: rowData.row.original?.payment_amount || '',
          save_rate_in_contract: rowData.row.original?.save_rate_in_contract || false,
          description: rowData.row.original.description,
          contractor: rowData.row.original.full_name,
          contract_start_date: rowData.row.original?.contract?.job?.starts_at || '',
          contract_end_date: rowData.row.original?.contract?.job?.ends_at || '',
          timesheet_aggregated_minutes: rowData.row.original?.timesheet_aggregated_minutes,
        })
        setEditModalKey('worked_hours')
        return
      default:
        break
    }
  }, [])

  useEffect(() => {
    if (debouncedValue?.length > 0) {
      postCalculateTotal({
        data: {
          contractor_payments: debouncedValue,
        },
      })
        .then((res) => setTotal(res.data))
        .catch(() => {
          setTotal(undefined)
        })
    } else {
      setTotal(undefined)
    }
  }, [debouncedValue, postCalculateTotal])

  const handleOpenAdditionPayment = ({ row }) => {
    navigate(`/pages/pay-contractors/payments/${row.original.id}`)
  }

  const handleDelete = (row) => {
    toggleDelete()
    setSelectedPayContractDelete(row.id)
  }

  const onChangeSearch = (evt) => {
    const { value } = evt.target
    setSearch(value)
    setPage(1)
  }

  const renderTable = () => {
    if (data?.results.length === 0) {
      return (
        <NotFound
          title="No active payments"
          description="The system generates active payments for contractors in a current period (this week, this half-month, this month)"
        />
      )
    }
    return (
      <CustomTable
        total={allPayments?.length || 0}
        allPayments={allPayments}
        fields={getFields({
          onClickEdit,
          handleOpenAdditionPayment,
          handleDelete,
        })}
        pageSize={limit}
        page={page}
        onPage={setPage}
        loading={isFetching}
        data={paginatedPayments || []}
        type="contractor-payments"
        onChangeSelect={setContractIds}
        selectedIds={contractIds}
        activePayments={activePayments}
      />
    )
  }

  const renderModal = React.useMemo(() => {
    switch (editModalKey) {
      case 'worked_hours':
        return (
          <RateModal
            closeModal={() => setEditModal(false)}
            values={rateValues}
            currency={currency}
            contractWageType={contractWageType}
            onSave={onSaveRate}
            loading={contractPaymentLoading}
            readOnly={isEditModalReadOnly}
            notificationText={isEditModalReadOnly ? 'Timesheet based contractor payments cannot be edited' : undefined}
          />
        )
      default:
        return null
    }
  }, [contractWageType, currency, editModalKey, rateValues, contractPaymentLoading])

  const renderTotal = () => {
    return total ? (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex gap-5">
          <Typography className="heading_semibold__20">
            Selected: {total?.currency?.sign} {SplitNumber(total?.total_amount)}
          </Typography>
          <Typography className="heading_semibold__20">Selected payments: {contractIds.length}</Typography>
        </div>
        {invoiceLoading ? (
          <DotWave size={32} speed={1} color="black" />
        ) : (
          <div className="d-flex gap-2">
            <Button
              data-testid="PayContractorsPage-65513B"
              priority="secondary"
              size="small"
              loading={postPreviewInvoice.isLoading}
              onClick={onPreviewInvoice}
            >
              Preview invoice
            </Button>
            <Button
              data-testid="PayContractorsPage-556AA2"
              priority="primary"
              size="small"
              onClick={handlePaymentConfirmList}
            >
              {createInvoiceLabel}
            </Button>
          </div>
        )}
      </div>
    ) : (
      <Typography className="text_regular__14 color_text_300 text-center">
        Select contractors to create an invoice
      </Typography>
    )
  }

  return (
    <div className="payContractor-page h-100">
      <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: '32px' }}>
        <div direction="horizontal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <PageTitle> Pay contractors</PageTitle>
          <PayContractorInfoButton />
        </div>

        <div className="d-flex gap-2">
          <Button
            data-testid="PayContractorsPage-B98962"
            priority="secondary"
            size="small"
            onClick={isOpenDownloadCsv.setTrue}
          >
            <HiDownload className="mr-1" />
            Download report
          </Button>
          <Button
            data-testid="PayContractorsPage-4BD84F"
            priority="primary_black"
            size="small"
            hidden={isEmpty}
            onClick={newPaymentModalState.setTrue}
          >
            <Icon icon={icons.addWhite} className="mr-2" />
            New payment
          </Button>
        </div>
      </div>
      {!isEmpty && (
        <Input
          data-testid="PayContractorsPage-4B608F"
          onChange={onChangeSearch}
          placeholder="Search"
          type="text"
          name="search"
          value={search}
          endIcon={<Icon icon={searchIcon} />}
          styleClass="employees-page-search"
        />
      )}

      {isEmpty && (
        <div className="d-flex justify-content-center align-items-center flex-grow-1">
          <div className="text-center">
            <img src="/assets/img/notFound.png" alt="" />
            <p className="heading_semibold__20 mb-3 mt-4">Its quiet in here ...</p>
            <p className="text_light__14 mb-4">We will let you know when we get any news for you.</p>
            <div className="d-flex align-items-center justify-content-center">
              <Button
                data-testid="PayContractorsPage-D5C560"
                priority="primary_black"
                size="small"
                onClick={newPaymentModalState.setTrue}
              >
                <Icon icon={icons.addWhite} className="mr-2" />
                New payment
              </Button>
            </div>
          </div>
        </div>
      )}

      <div className="d-flex flex-column justify-content-between" style={{ marginTop: '8px' }}>
        {renderTable()}
        <div className="payContractor-bottom-wrapper">
          <div className="payContractor-bottom d-flex justify-content-center">
            {totalLoading ? <DotWave size={32} speed={1} color="black" /> : renderTotal()}
          </div>
        </div>
      </div>
      {invoiceCreateErrors && (
        <InvoiceCreateErrorsModal
          errors={invoiceCreateErrors}
          onClose={() => setInvoiceCreateErrors(null)}
          onOk={() => navigate('/pages/invoices')}
        />
      )}
      {successAlert && (
        <Modal
          sideClose
          style={{
            transition: 'height 300ms linear',
            padding: '36px 40px',
            minHeight: '381px',
            borderRadius: 28,
          }}
          onClose={closeSuccessAlert}
          className="d-flex align-items-center flex-column"
        >
          <div className="d-flex justify-content-end w-100 mb-4">
            <Icon
              // eslint-disable-next-line react/destructuring-assignment
              onClick={closeSuccessAlert}
              icon={clearMedium}
              fill="#878787"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <img src="/assets/img/oc-hand-holding-papers.png" alt="" />
          <Typography className="heading_semibold__24 mb-3 text-center mt-4">Contractor Invoice Created</Typography>
          <Typography className="text_regular__14 color_text_300 text-center">
            As soon as we receive your payment it’ll be automatically transferred to your contractors. You can check the
            status of the payment in the{' '}
            <Link
              to="/pages/invoices"
              className="invoice-link"
              onClick={() => runKompassifySurvey(KOMPASSIFY_SURVEYS.InvoiceCreated)}
            >
              Invoices
            </Link>{' '}
            tab.
          </Typography>
          {transactionId && transactionId.length > 0 && (
            <NotificationBlock
              styledClass="notification"
              render={
                <Typography className="text_light__12">
                  Please ensure that you use the PRID as your payment reference
                </Typography>
              }
            />
          )}
        </Modal>
      )}
      {editModal && renderModal}
      {newPaymentModalState.value && <NewPaymentModal close={newPaymentModalState.setFalse} refetch={refetch} />}
      <EditModal
        visible={visibleDelete}
        title="Delete pay contractor"
        onSave={() => {}}
        footer={false}
        closeModal={toggleDelete}
      >
        <Typography className="heading_semi__16">Are you sure you want to delete this pay contractor?</Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="PayContractorsPage-DA24F6"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={toggleDelete}
          >
            Cancel
          </Button>
          <Button
            data-testid="PayContractorsPage-5961A7"
            priority="primary_dangerous"
            size="small"
            loading={deleteMutate.isLoading}
            onClick={() => deleteMutate.mutate(selectedPayContractDelete || '')}
          >
            Delete
          </Button>
        </div>
      </EditModal>

      {isOpenDownloadCsv.value && <DownloadReport onClose={isOpenDownloadCsv.setFalse} />}

      {isCreateInvoiceConfirmListOpen.value && (
        <CreateInvoiceConfirmModal
          onClose={isCreateInvoiceConfirmListOpen.setFalse}
          onConfirm={onCreateInvoice}
          loading={invoiceLoading}
          title={createInvoiceLabel}
          contractIds={contractIds}
          paymentPluralLabel={paymentPluralLabel}
          paymentsListToConfirm={paymentsListToConfirm}
        />
      )}
    </div>
  )
}
