export const commonDocumentMimeTypes = {
  'image/*': ['.png', '.jpg', '.jpeg'],
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'text/plain': ['.txt'],
}

export const PAYMENT_FREQUENCY = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'semi_monthly', label: 'Semi-monthly' },
  { value: 'biweekly', label: 'Every 2 weeks' },
  { value: 'one_time_payment', label: 'None' },
]

export const PaymentValue = {
  weekly: 'Weekly',
  monthly: 'Monthly',
  semi_monthly: 'Semi-monthly',
  one_time_payment: 'One time payment',
  biweekly: 'Every 2 weeks',
}

export const WAGE_TYPE = {
  HOURLY: 'hourly',
  MONTHLY: 'monthly',
  DAILY: 'daily',
  MILESTONE: 'milestone',
}

export const PaymentMethods = {
  nium: 'nium',
  ach: 'ach',
  wise: 'wise',
}
export const PaymentMethodsLabel = {
  [PaymentMethods.nium]: 'Nium',
  [PaymentMethods.ach]: 'ACH',
  [PaymentMethods.wise]: 'Wire transfer',
}

export const fieldNameMap = {
  name: 'Name',
  firstName: 'First name',
  lastName: 'Last name',
  middleName: 'Middle name',
  email: 'Email',
  jobTitle: 'Job title',
  jobDescription: 'Description of work',
  department: 'Department',
  phoneNumber: 'Phone number',
  companyName: 'Legal company name',
  country: 'Country',
  currency: 'Currency',
  receivingCurrency: 'Receiving currency',
  headquarters: 'Headquarters',
  state: 'State',
  homeState: 'Region / State / Province',
  streetAddress: 'Street address',
  workingCountry: 'Country of hire',
  addressLine: 'Address line',
  city: 'City',
  postalCode: 'Zip / Postal code',
  startDate: 'Start date',
  endDate: 'End date',
  rateType: 'Rate type',
  contractCurrency: 'Contract currency',
  contractorRate: 'Contractor rate',
  paymentFrequency: 'Payment frequency',
  contractTermName: 'Contract term',
  registeredAddress: 'Registered address',
  cycleStartDate: 'Payment cycle start date',
}

export const SEND_TYPES = {
  SELECT: 'SELECT',
  INVITE: 'INVITE',
}

export const TEMPLATE_TYPES = {
  JOB_CONTRACT: 'job_contract',
  SALARY_CHANGE: 'salary_change',
  CONTRACT_TERMINATION: 'contract_termination',
  MSA: 'msa',
  APPENDIX: 'appendix',
  CONTRACTOR_EMPLOYMENT: 'contractor_employment',
  AOR_CONTRACT: 'aor_contract',
  COMPLIANCE: 'compliance',
}

export const EOR_TYPES = {
  TRADITIONAL: 'traditional_eor',
  SAAS: 'saas_eor',
}
export const PARENTAL_LEAVE_TYPE = {
  MATERNITRY: 'maternity_days',
  PATERNITY: 'paternity_days',
}

export const COMPANY_STATES = {
  ONBOARDING: 'onboarding',
  SIGNING: 'signing',
  CREATED: 'created',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ARCHIVED: 'archived',
  BEING_VERIFIED: 'being_verified',
  MSA_SIGNED: 'msa_signed',
}

export const EMPLOYEE_STATES = {
  ONBOARDING: 'onboarding',
  CREATED: 'created',
  SIGNING: 'signing',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ARCHIVED: 'archived',
  BEING_VERIFIED: 'being_verified',
  COMPLETED: 'completed',
  DRAFT: 'draft',
  INVITED: 'invited',
}

export const CONTRACT_TYPE = {
  FULL_TIME_EMPLOYEE: 'full_time_employee',
  CONTRACTOR: 'contractor',
}

export const SIGNATURE_STATES = {
  AWAITING: 'awaiting_signature',
  SIGNED: 'signed',
}

export const REMO_HEALTH_PACKAGE = {
  CORE: 'core',
  STANDARD: 'standard',
  PREMIUM: 'premium',
  PLUS: 'plus',
}

export const BENEFIT_TYPES = {
  EQUIPMENT_DELIVERY: 'equipment_delivery',
  REMOHEALTH: 'remohealth',
}

export const REMOHEALTH_STATUSES = {
  CREATED: 'created',
  ACTIVE: 'active',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
  SUBMITTED: 'app_submitted',
  ACCEPTED: 'app_accepted',
  MEDICAL_OUTSTANDING: 'medical_outstanding',
  INFO_OUTSTANDING: 'info_outstanding',
}

export const PAYROLL_STATUSES = {
  CREATED: 'CREATED',
  APPROVED_BY_COMPANY: 'APPROVED_BY_COMPANY',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  DELETED: 'DELETED',
  MOVED_TO_NEXT_MONTH: 'MOVED_TO_NEXT_MONTH',
  INVOICED: 'INVOICED',
}

export const EXPENSE_STATUSES = {
  CREATED: 'CREATED',
  APPROVED_BY_COMPANY: 'APPROVED_BY_COMPANY',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  DELETED: 'DELETED',
  MOVED_TO_NEXT_MONTH: 'MOVED_TO_NEXT_MONTH',
}

export const USER_GROUPS = {
  FINANCE_SPECIALIST: 1,
  PTO_VIEWER: 2,
  PTO_MANAGER: 3,
  EXPENSE_VIEWER: 4,
  EXPENSE_MANAGER: 5,
  FINANCE_AP_SPECIALIST: 6,
  HR_SPECIALIST: 7,
  SUPERUSER: 8,
}

export const RESIDENCY_TYPES = {
  RESIDENT: 'resident',
  NON_RESIDENT: 'non_resident',
}

export const PRONOUNS = {
  SHE_HER: 'she/her',
  HE_HIM: 'he/him',
  THEY_THEM: 'they/them',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
}

export const MASS_IMPORT_STATE = {
  UPLOAD_CSV: 'upload_csv',
  VALIDATE_DATA: 'validate_data',
  CONFIRM_IMPORT: 'confirm_import',
  UPLOAD_WORK_AGREEMENT: 'upload_work_agreement',
  COMPLETED: 'completed',
}

export const TIME_OFF_STATUSES = {
  APPROVED: 'APPROVED',
  CANCELED: 'CANCELED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  TAKEN: 'TAKEN',
}

export const TIME_OFF_STATUSES_MAP = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  CANCELED: 'Canceled',
  TAKEN: 'Taken',
}

export const PAID_TIME_OFF_LEAVE_TYPES = {
  VACATION: 'Vacation',
  SICK_LEAVE: 'Sick leave',
  PARENTAL_LEAVE: 'Parental leave',
  BEREAVEMENT: 'Bereavement',
}

export const TIME_OFF_LEAVE_TYPES = {
  VACATION: 'Vacation',
  SICK_LEAVE: 'Sick leave',
  PARENTAL_LEAVE: 'Parental leave',
  BEREAVEMENT: 'Bereavement',
  CAREGIVER_LEAVE: 'Caregiver leave',
  CIVIC_DUTIES: 'Civic duties',
  EDUCATIONAL_LEAVE: 'Educational leave',
  MARRIAGE_LEAVE: 'Marriage leave',
  MILITARY_LEAVE: 'Military leave',
  MILITARY_MEDICAL_EXAMINATION: 'Military medical examination',
  MILITARY_TRAINING_ASSEMBLY: 'Military training assembly',
  RELIGIOUS_OBSERVANCE: 'Religious observance',
  SABBATICAL: 'Sabbatical',
}

export const BALANCE_BREAKDOWN_TYPES = {
  VACATION: 'vacation',
  ADHOC: 'adhoc',
  ANNUAL_ENTITLEMENT: 'annual_entitlement',
  CARRYOVER: 'carryover',
}

export const BALANCE_BREAKDOWN_TYPES_MAP = {
  vacation: 'Vacation',
  adhoc: 'Ad-hoc',
  annual_entitlement: 'Annual entitlement',
  carryover: 'Carryover',
}

export const BALANCE_BREAKDOWN_ACTION_TYPES = {
  USER_GENERATED: 'user_generated',
  AUTO_GENERATED: 'auto_generated',
}

export const BALANCE_BREAKDOWN_ACTION_TYPES_MAP = {
  user_generated: 'User Generated',
  auto_generated: 'Auto Generated',
}

export const USER_ROLES_MAP = {
  company_owner: `Company Admin`,
  'company owner': `Company Admin`,
  company_admin: `Manager`,
  partner_owner: `Partner Admin`,
  partner_manager: `Partner Manager`,
  admin: `System Admin`,
  dummy: `System Admin`,
  employee: `Employee`,
}

export const LeaveType = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
}

export const CANCEL_ONBOARDING_REASONS_MAP = {
  ee_declined_offer: 'EE declined offer',
  hiring_with_another_eor: 'Hiring with Another EOR',
  hiring_as_contractor: 'Hiring as a contractor',
  visa_issue: 'Visa issues',
  failed_background_check: 'Failed Background Check',
  non_responsive_ee: 'Non-Responsive EE',
  decided_not_to_hire_this_ee: 'Decided not to hire this EE',
  position_canceled: 'Position canceled',
  OTHER: 'Other',
}

export const SUB_STATE_CHANGE_REQUEST_TYPES = {
  OFFBOARDING: 'offboarding',
  CANCELLED: 'cancelled',
  ON_HOLD: 'on_hold',
}

export const AP_TYPE = {
  BONUS: 'BONUS',
  ALLOWANCE: 'ALLOWANCE',
  OTHER: 'OTHER',
}

export const RECURRENCE_FREQUENCY_TYPES = {
  ONE_TIME: 'ONE_TIME',
  MONTHLY: 'MONTHLY',
  EVERY_SECOND_MONTH: 'EVERY_SECOND_MONTH',
  QUARTERLY: 'QUARTERLY',
}

export const RECURRENCE_FREQUENCY_TYPES_MAP = {
  ONE_TIME: 'One-time',
  MONTHLY: 'Monthly',
  EVERY_SECOND_MONTH: 'Every 2 months',
  QUARTERLY: 'Every 3 months',
}

export const DEFAULT_CURRENCIES = {
  USD: 'USD',
  CAD: 'CAD',
  EUR: 'EUR',
  GBP: 'GBP',
  AUD: 'AUD',
  SGD: 'SGD',
  NZD: 'NZD',
}

export const CONTRACT_FIELDS = [
  'first_name',
  'middle_name',
  'last_name',
  'birth_date',
  'living_country',
  'street_address',
  'state',
  'city',
  'postal_code',
  'address_line',
  'home_living_country',
  'home_street_address',
  'home_state',
  'home_city',
  'home_postal_code',
  'home_address_line',
  'position',
  'position_description',
  'currency',
  'receiving_currency',
  'contractor_rate',
  'contractor_wage_type',
  'working_country',
  'contractor_payment_frequency',
  'requested_starts_at',
  'ends_at',
]
export const KOMPASSIFY_SURVEYS = {
  ContractorAdded: '037353bd-99e2-4814-a7a0-3f00f6295e8b',
  ContractCreated: 'e27870cd-d596-4fc6-8c9f-f1d8deb4320b',
  InvoiceCreated: '16301256-68a3-49c9-a527-9be33c3616c1',
}

export const EMPLOYEE_PAY_TYPE = {
  Salaried: 'salaried',
  Hourly: 'hourly',
}

export const EMPLOYEE_PAYMENT_FREQUENCY_MAP = {
  monthly: 'Monthly',
  semi_monthly: 'Semi-monthly',
  weekly: 'Weekly',
  every_two_weeks: 'Every 2 weeks',
}

export const EMPLOYMENT_TYPE = {
  FullTime: 'full_time',
  PartTime: 'part_time',
}

export const EMPLOYMENT_TYPE_MAP = {
  full_time: 'Full-time',
  part_time: 'Part-time',
}

export const OFFBOARDING_TYPE = {
  Termination: 'termination_company_initiated',
  Resignation: 'resignation_employee_initiated',
}

export const OFFBOARDING_TYPE_MAP = {
  termination_company_initiated: 'Termination - company initiated',
  resignation_employee_initiated: 'Resignation - employee initiated',
}

export const OFFBOARDING_TERMINATION_REASONS = {
  business_closure: 'business_closure',
  reduction_in_operations: 'reduction_in_operations',
  redundancy: 'redundancy',
  set_up_own_entity: 'set_up_own_entity',
  acquisition: 'acquisition',
  performance_issues: 'performance_issues',
  contract_breach: 'contract_breach',
  misconduct: 'misconduct',
  not_the_right_fit: 'not_the_right_fit',
  remofirst_service: 'remofirst_service',
  transfer_to_another_eor: 'transfer_to_another_eor',
  switching_to_contractor: 'switching_to_contractor',
  visa_issues: 'visa_issues',
  end_of_contract: 'end_of_contract',
  relocation: 'relocation',
  transfer_to_another_lp: 'transfer_to_another_lp',
  remofirst_decision: 'remofirst_decision',
}
export const OFFBOARDING_TERMINATION_REASONS_MAP = {
  business_closure: {
    groupLabel: 'Business Changes',
    label: 'Business closure',
  },
  reduction_in_operations: {
    groupLabel: 'Business Changes',
    label: 'Reduction in operations',
  },
  redundancy: {
    groupLabel: 'Business Changes',
    label: 'Redundancy',
  },
  set_up_own_entity: {
    groupLabel: 'Business Changes',
    label: 'Set up own entity',
  },
  acquisition: {
    groupLabel: 'Business Changes',
    label: 'Acquisition',
  },
  performance_issues: {
    groupLabel: 'Employee Performance',
    label: 'Performance Issues',
  },
  contract_breach: {
    groupLabel: 'Employee Performance',
    label: 'Contract breach',
  },
  misconduct: {
    groupLabel: 'Employee Performance',
    label: 'Misconduct',
  },
  not_the_right_fit: {
    groupLabel: 'Employee Performance',
    label: 'Not the right fit',
  },
  remofirst_service: {
    groupLabel: 'Service and Provider Changes',
    label: 'Remofirst Service',
  },
  transfer_to_another_eor: {
    groupLabel: 'Service and Provider Changes',
    label: 'Transfer to another EOR',
  },
  switching_to_contractor: {
    groupLabel: 'Service and Provider Changes',
    label: 'Switching to contractor',
  },
  visa_issues: {
    groupLabel: 'Legal and Compliance',
    label: 'Visa Issues',
  },
  end_of_contract: {
    groupLabel: 'Legal and Compliance',
    label: 'End of Contract',
  },
  relocation: {
    groupLabel: 'Legal and Compliance',
    label: 'Relocation',
  },
  transfer_to_another_lp: {
    groupLabel: 'Internal',
    label: 'Transfer to another LP',
  },
  remofirst_decision: {
    groupLabel: 'Internal',
    label: 'Remofirst decision',
  },
}

export const OFFBOARDING_RESIGNATION_REASONS_MAP = {
  accepted_new_offer: {
    groupLabel: 'Employee Personal reasons',
    label: 'Accepted new offer',
  },
  other_personal_reason: {
    groupLabel: 'Employee Personal reasons',
    label: 'Other Personal reasons',
  },
  not_the_right_fit: {
    groupLabel: 'Employee Performance',
    label: 'Not the right fit',
  },
  switching_to_contractor: {
    groupLabel: 'Service and Provider Changes',
    label: 'Switching to contractor',
  },
  relocation: {
    groupLabel: 'Legal and Compliance',
    label: 'Relocation',
  },
  visa_issues: {
    groupLabel: 'Legal and Compliance',
    label: 'Visa Issues',
  },
  end_of_contract: {
    groupLabel: 'Legal and Compliance',
    label: 'End of Contract / Project completion',
  },
  transfer_to_another_eor: {
    groupLabel: 'Internal',
    label: 'Transfer to another EOR',
  },
}

export const EMPLOYEE_PERFORMANCE_REASON_BASED_ACTIONS_MAP = {
  clear_instructions: 'The employee was provided with clear instructions on work expectations',
  coaching_training: 'You have provided coaching, mentoring, training, or an improvement plan to address the issues.',
  regular_meetings: 'You have held regular meetings with the employee to address the issues and provide feedback.',
  feedback_delivered:
    'Feedback was officially delivered to the employee, documented, and signed by both the employee and the line manager.',
  warnings_given: 'You have provided the employee with warnings or advised them of poor attitude, work ethics, etc.',
  evidence_available: 'You have evidence of the said issues.',
  none_taken: 'None of the above took place.',
}

export const EMPLOYEE_OFFBOARDING_LEAVE_TYPES = {
  vacation: 'Vacation',
  sick_leave: 'Sick leave',
  maternity: 'Maternity leave',
  parental: 'Parental leave',
  // pregnancy: 'Pregnancy',
}

export const EMPLOYEE_NOTICE_PERIOD_ARRANGEMENT = {
  worked: 'worked',
  payment_in_lieu: 'payment_in_lieu',
}

export const EMPLOYEE_NOTICE_PERIOD_ARRANGEMENT_MAP = {
  worked: 'Notice should be worked',
  payment_in_lieu: 'Notice should be paid in lieu (garden leave)',
}

export const OFFBOARDING_PTO_COMMUNICATION_METHOD = {
  leaves_logged_on_platform: 'leaves_logged_on_platform',
  provide_pto_record: 'provide_pto_record',
}

export const OFFBOARDING_PTO_COMMUNICATION_METHOD_MAP = {
  leaves_logged_on_platform: 'All used leaves have been logged and approved on RemoFirst platform',
  provide_pto_record: 'Provide the employee’s PTO record so our team can reconcile it',
}

export const PROPERTY_RETURN_INSTRUCTION = {
  yes: 'yes',
  not_yet: 'not_yet',
  not_applicable: 'not_applicable',
}

export const HIRING_PLAN_REMOFIRST = {
  next_3_months: 'next_3_months',
  three_to_six_months: 'three_to_six_months',
  six_months_or_later: 'six_months_or_later',
  no_plan: 'no_plan',
}

export const HIRING_PLAN_REMOFIRST_MAP = {
  next_3_months: 'Yes, in the next 3 months',
  three_to_six_months: 'Yes, in 3–6 months',
  six_months_or_later: 'Yes, in 6 months or later',
  no_plan: 'No plan',
}

export const TIMESHEET_STATE = {
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  DRAFT: 'DRAFT',
  ARCHIVED: 'ARCHIVED',
  PENDING_INVOICE: 'PENDING_INVOICE',
  PENDING_PAYMENT_APPROVAL: 'PENDING_PAYMENT_APPROVAL',
  PAID: 'PAID',
}
