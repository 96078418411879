import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { EMPLOYEE_STATES } from '@core/constants'
import { AppContext } from '@core/context'
import { fetchContractors } from '@services/app.service'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'

export const ContractorStep = ({ onNext }) => {
  const { countries, profile } = useContext(AppContext)

  const { data: contractors, isFetching } = useQuery(fetchContractors.key, {
    queryFn: () =>
      fetchContractors.fetch({
        company_id: profile.id,
        contract_type: 'contractor',
        offset: 0,
        limit: 1000,
      }),
    enabled: !!profile.id,
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  const submit = (formValues) => {
    onNext({
      id: formValues.contractor.value,
      working_country: formValues.working_country?.value || formValues.working_country,
      home_address: {
        state: formValues.state,
      },
    })
  }

  return (
    <form className="d-flex flex-column h-100 employees-page__form" onSubmit={handleSubmit(submit)}>
      <section className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="contractor"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="contractor-step-D98D6C"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e)
                    setValue('working_country', e.workingCountry)
                  }}
                  isDisabled={isFetching}
                  isRequired
                  label={isFetching ? 'Loading...' : 'Contractor name'}
                  options={
                    contractors?.results.length > 0
                      ? contractors?.results
                          ?.filter((contract) => contract.state !== EMPLOYEE_STATES.INACTIVE)
                          .map((contract) => ({
                            value: contract.id,
                            label: contract.full_name,
                            workingCountry: contract.working_country.id,
                            state: contract.home_address?.state,
                          }))
                      : []
                  }
                />
              )
            }}
          />
          {errors?.contractor && (
            <Typography className="text_regular__14 color_red">{errors?.contractor.message}</Typography>
          )}
        </div>

        {countries && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="working_country"
              rules={{ required: 'Country is required' }}
              render={({ field }) => (
                <Select
                  data-testid="contractor-step-CD4F8F"
                  {...field}
                  isRequired
                  options={countries.map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))}
                  placeholder="e.g United Kingdom"
                  label="Country"
                />
              )}
            />
            {errors.working_country && (
              <Typography className="text_regular__14 color_red">{errors.working_country.message}</Typography>
            )}
          </div>
        )}

        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="state"
            render={({ field }) => (
              <Input
                data-testid="contractor-step-A87B1C"
                {...field}
                label="Region / State / Province"
                placeholder="State"
              />
            )}
          />
        </div>
      </section>
      <div className="pb-5 align-self-center">
        <Button data-testid="contractor-step-E33473" type="submit" className="align-self-end">
          Save and continue
        </Button>
      </div>
    </form>
  )
}
