import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { InfoMessage } from '@components/info-message/info-message.component'
import { useToast } from '@core/hooks/useNotification'
import { TimeOffAccrualRule, TimeOffAnnualDaysRule, TimeOffSickLeaveDaysRule } from '@core/types/time-off.types'
import { getContractsPaidLeaves, postContractsPaidLeaves } from '@services/contract.service'
import { fetchCountryPaidLeavesById, fetchCountrySpecificPaidById } from '@services/countries.service'
import { DotWave } from '@uiball/loaders'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import { PaidBereavementModal } from './PaidBereavementModal'
import { PaidParentalModal } from './PaidParentalModal'
import { PaidSickLeaveModal } from './PaidSickLeaveModal'
import { PaidTimeOffModal } from './PaidTimeOffModal'
import { TimeOffCarryOverForm } from './TimeOffCarryOverForm'

const Container = styled.div`
  border: 2px solid #f3f3f3;
  border-radius: 8px;
  height: 100%;
  padding: 16px;
  padding-bottom: 0;
`

export const TIME_OFF_INFO_VIEW_FORM = 'TIME_OFF_INFO_VIEW_FORM'

export default function TimeOffEmployee({ employee, refetchEmployee }) {
  const { data, refetch, isLoading } = useQuery(['getContractsPaidLeaves', employee], {
    queryFn: () => getContractsPaidLeaves(employee.id),
  })

  const { data: countryTimeoffPolicyData, isLoading: isCountryTimeoffPolicyLoading } = useQuery(
    [fetchCountryPaidLeavesById.key, employee.working_country.id],
    {
      queryFn: () => fetchCountryPaidLeavesById(employee.working_country.id),
    }
  )

  const { data: countrySpecificPaidLeaves } = useQuery(
    ['fetchCountrySpecificPaidById', employee?.working_country?.id],
    {
      queryFn: () => fetchCountrySpecificPaidById(employee?.working_country?.id),
      enabled: !!employee?.working_country?.id,
    }
  )

  const isCarryOverAllowedByCountry = countryTimeoffPolicyData?.allow_carryover

  const { successAlert } = useToast()

  const timeOffOpen = useBoolean(false)
  const sickOpen = useBoolean(false)
  const parentalOpen = useBoolean(false)
  const bereavementOpen = useBoolean(false)

  const updateContractsPaidLeaves = useMutation({
    mutationFn: (payload) => postContractsPaidLeaves(employee.id, payload),
    onSuccess: () => {
      refetch()
      successAlert('Successfully updated!')
      timeOffOpen.setFalse()
      parentalOpen.setFalse()
      sickOpen.setFalse()
      bereavementOpen.setFalse()
    },
  })

  if (isLoading || isCountryTimeoffPolicyLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <DotWave />
      </div>
    )
  }

  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <Typography className="heading_semibold__20 color_black">Time-off</Typography>
        </div>
        {data && (
          <div className="col-10 mb-3">
            <Container>
              <InfoMessage
                type="secondary"
                message="The parameters in this section are defined in the employee agreement and cannot be modified without an amendment to the agreement."
                fullWidth
              />
              <form id={TIME_OFF_INFO_VIEW_FORM}>
                <div className="w-100 remo-form-input">
                  <Select
                    label="Annual paid time off days"
                    isDisabled
                    value={employee?.paid_leave?.annual_days_rule}
                    options={[
                      {
                        value: TimeOffAnnualDaysRule.Statutory,
                        label: `Statutory annual leave by country${
                          countryTimeoffPolicyData ? ` - ${countryTimeoffPolicyData?.statutory_annual_days} days` : ''
                        }`,
                      },
                      {
                        value: TimeOffAnnualDaysRule.Recommended,
                        label: `Recommended annual leave${
                          countryTimeoffPolicyData
                            ? ` - ${countryTimeoffPolicyData?.recommendation_by_remofirst_days} days`
                            : ''
                        }`,
                      },
                      {
                        value: TimeOffAnnualDaysRule.TopOfMarket,
                        label: `Top of market annual leave${
                          countryTimeoffPolicyData
                            ? ` - ${countryTimeoffPolicyData?.recommendation_by_market_days} days`
                            : ''
                        }`,
                      },
                    ]}
                  />
                </div>

                <div className="w-100 remo-form-input">
                  <Select
                    label="Sick leave days"
                    isDisabled
                    value={employee?.paid_leave?.sick_leave_days_rule}
                    options={[
                      {
                        value: TimeOffSickLeaveDaysRule.Statutory,
                        label: `Statutory sick leave by country${
                          countrySpecificPaidLeaves ? ` - ${countrySpecificPaidLeaves?.sick_days} days` : ''
                        }`,
                      },
                      {
                        value: TimeOffSickLeaveDaysRule.Unlimited,
                        label: 'Unlimited',
                      },
                    ]}
                  />
                </div>

                <div className="w-100 remo-form-input">
                  <RadioButton
                    label="Paid time off accrual rule"
                    layout="vertical"
                    disabled
                    value={employee?.paid_leave?.accrual_rule}
                    column
                    options={[
                      {
                        id: TimeOffAccrualRule.AllAtOnce,
                        text: 'All at once: employee will receive all their Annual Days balance immediately',
                        value: TimeOffAccrualRule.AllAtOnce,
                      },
                      {
                        id: TimeOffAccrualRule.Accrued,
                        text: 'Accrued: employee will accumulate their annual days off over time',
                        value: TimeOffAccrualRule.Accrued,
                      },
                    ]}
                  />
                </div>
              </form>
            </Container>
          </div>
        )}
        {employee?.contract_type !== 'contractor' && (
          <div className="col-10">
            <Container>
              <div className="d-flex flex-column">
                {isCarryOverAllowedByCountry ? (
                  <InfoMessage
                    type="success"
                    message="You can configure the carryover settings below. By default, these settings inherit your company’s time-off policy, but you can override them for individual employees."
                    fullWidth
                  />
                ) : (
                  <InfoMessage message={`Carryover is not supported in ${employee.working_country.name}`} fullWidth />
                )}
                <TimeOffCarryOverForm
                  employee={employee}
                  refetchEmployee={refetchEmployee}
                  isCarryOverAllowedByCountry={isCarryOverAllowedByCountry}
                />
              </div>
            </Container>
          </div>
        )}
      </div>

      {parentalOpen.value && data && (
        <PaidParentalModal
          title="Paid parental leave"
          paidParentalLeave={data.leave_days.paid_parental_leave}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={parentalOpen.setFalse}
          onSubmit={updateContractsPaidLeaves.mutate}
        />
      )}
      {bereavementOpen.value && data && (
        <PaidBereavementModal
          title="Paid bereavement leave"
          paidBereavementLeave={data.leave_days.paid_bereavement_leave}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={bereavementOpen.setFalse}
          onSubmit={updateContractsPaidLeaves.mutate}
        />
      )}
      {timeOffOpen.value && data && (
        <PaidTimeOffModal
          title="Paid time - off days"
          loading={updateContractsPaidLeaves.isLoading}
          paidLeave={data.leave_days.paid_leave}
          onClose={timeOffOpen.setFalse}
          onSubmit={updateContractsPaidLeaves.mutate}
        />
      )}
      {sickOpen.value && data && (
        <PaidSickLeaveModal
          title="Paid sick leave"
          paidSickLeave={data.leave_days.paid_sick_leave}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={sickOpen.setFalse}
          onSubmit={updateContractsPaidLeaves.mutate}
        />
      )}
    </>
  )
}
