import Confirm from '@atoms/Confirm'
import { AbsoluteSpinner } from '@atoms/Spinner/AbsoluteSpinner'
import Typography from '@atoms/Typography/Typography'
import { getPaymentsTotal } from '@core/utils'
import { DotWave } from '@uiball/loaders'
import React, { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

import {
  fetchCreateAdditionPayment,
  fetchDeleteAdditionPayment,
  fetchUpdateAdditionPayment,
} from '../../services/payments.service'
import { getPayrollById } from '../../services/payroll.service'
import MyAccordion from './Accordion'
import CreateModal from './CreateModal'
import Header from './Header'

export default function PayrollsDetailEditPage() {
  const params = useParams()
  const { value, toggle } = useBoolean(false)
  const { value: modalValue, toggle: modalToggle } = useBoolean(false)
  const { value: modalPositiveValue, toggle: modalPositiveToggle } = useBoolean(false)
  const [paymentIdForDelete, setPaymentIdForDelete] = useState(null)

  const { data, isFetching, isLoading, refetch } = useQuery('additionalPaymentPayroll', {
    queryFn: () => getPayrollById(params.id),
    enabled: !!params.id,
  })

  const updateMutate = useMutation({
    mutationFn: ({ id, body }) => fetchUpdateAdditionPayment({ id, body }),
    onSuccess: () => {
      refetch()
    },
  })
  const deleteMutate = useMutation({
    mutationFn: (id) => fetchDeleteAdditionPayment(id),
    onSuccess: () => {
      refetch()
      setPaymentIdForDelete(null)
    },
  })
  const createMurate = useMutation({
    mutationFn: (body) => fetchCreateAdditionPayment(body),
    onSuccess: (res) => {
      refetch()
      if (res.state === 'MOVED_TO_NEXT_MONTH') modalToggle()
      if (res.state === 'APPROVED_BY_COMPANY') modalPositiveToggle()
      toggle()
    },
  })

  // TODO TAX
  const handleSave = useCallback(
    ({ id, body }) => {
      updateMutate.mutate({
        id,
        body: { ...body, amount: Number(body.amount) },
      })
    },
    [updateMutate]
  )

  const handleDelete = useCallback(
    (id) => {
      deleteMutate.mutate(id)
    },
    [deleteMutate]
  )

  // TODO TAX
  const handleCreate = useCallback(
    (body) => {
      const localTime = Intl.DateTimeFormat().resolvedOptions().timeZone
      createMurate.mutate({
        ...body,
        local_timezone: localTime,
        amount: Number(body.amount),
        payroll: Number(params.id),
      })
    },
    [createMurate, params]
  )

  if (isLoading) {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <DotWave />
      </div>
    )
  }

  return (
    <>
      <Header
        companyCurrency={data.company_currency.sign || data.company_currency.short_code}
        total={getPaymentsTotal(data.additional_payments)}
        fullName={`${data?.user?.full_name}`}
        onAdd={toggle}
      />
      <AbsoluteSpinner isFetching={isFetching}>
        <MyAccordion
          isLoading={updateMutate.isLoading}
          payroll={data}
          onDelete={(id) => setPaymentIdForDelete(id)}
          onSave={handleSave}
        />
      </AbsoluteSpinner>

      {value && data && (
        <CreateModal data={data} isLoading={createMurate.isLoading} onHide={toggle} onCreate={handleCreate} />
      )}

      {!!paymentIdForDelete && (
        <Confirm
          title="Delete Payment"
          isLoading={deleteMutate.isLoading}
          onCancel={() => setPaymentIdForDelete(null)}
          onYes={() => handleDelete(paymentIdForDelete)}
        >
          <Typography>Are you sure that you want to delete this additional payment?</Typography>
        </Confirm>
      )}
      {modalValue && (
        <Confirm
          title=""
          cancelButtonProps={{
            text: 'Close',
          }}
          onCancel={modalToggle}
        >
          <Typography>
            This payment will be automatically added to the next payroll cycle as the cut-off date, day (3) of this
            month, has passed.
          </Typography>
        </Confirm>
      )}
      {modalPositiveValue && (
        <Confirm
          title=""
          cancelButtonProps={{
            text: 'Close',
          }}
          onCancel={modalPositiveToggle}
        >
          <Typography>This additional payment will be added to the current months payroll.</Typography>
        </Confirm>
      )}
    </>
  )
}
