import styled, { css } from 'styled-components'

const Form = styled.form`
  width: 100%;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const FormControl = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 0.5rem;
  }
`

const Label = styled.label`
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
`
const LabelDescription = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  color: #878787;
  line-height: 1rem;
  margin-top: 4px;
  margin-bottom: 12px;
`
const RadioBox = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`
const RadioBoxBody = styled.div`
  flex: 1;
`
const RadioInput = styled.input`
  margin-right: 1rem;
`

const RadioContent = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(31, 31, 31, 0.12);
  padding: 24px 32px;
  margin-bottom: 8px;
`

export { Form, FormControl, FormGroup, Label, LabelDescription, RadioBox, RadioBoxBody, RadioContent, RadioInput }
