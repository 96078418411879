import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import ModalSide from '@atoms/ModalSide/ModalSide'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { RECURRENCE_FREQUENCY_TYPES, RECURRENCE_FREQUENCY_TYPES_MAP } from '@core/constants'
import { useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import { calculateAdditionalPaymentEmployerCostTaxes } from '@services/employer-cost.service'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useDebounceValue } from 'usehooks-ts'

import { Styled } from './additional-compensation-modal.styles'

const FORM_ID = 'ADDITIONAL_COMPENSATION_FORM'

export const AdditionalCompensationModal = ({
  mode,
  employerCostCompensations,
  countryId,
  selectedCompensation,
  onClose,
  loading,
  onAdd,
  onEdit,
  countryCurrency,
}) => {
  const { currencies } = useApp()
  const currencyOption = {
    value: countryCurrency?.id,
    label: countryCurrency?.short_code,
  }
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      title: selectedCompensation?.title ?? '',
      amount: selectedCompensation?.amount ?? '',
      currency: selectedCompensation?.currency || currencyOption || '',
      frequency: selectedCompensation?.frequency ?? '',
      isContractual: selectedCompensation?.isContractual ?? false,
      hasEnd: selectedCompensation?.hasEnd ?? false,
      occurrences: selectedCompensation?.occurences,
      ap_type: 'OTHER',
      tax: selectedCompensation?.tax,
      sub_ap_type:
        employerCostCompensations.find((f) => f.name === selectedCompensation?.sub_ap_type)?.id ||
        selectedCompensation?.sub_ap_type?.value,
      id: selectedCompensation?.id,
    },
  })

  const watchFrequency = watch('frequency')
  const watchHasEnd = watch('hasEnd')
  const amount = watch('amount')
  const type = watch('sub_ap_type')
  const taxAmount = watch('tax')

  const [debounceAmount, setDebounceAmount] = useDebounceValue(amount, 1000)

  const { isLoading } = useQuery([calculateAdditionalPaymentEmployerCostTaxes.key, debounceAmount, type, countryId], {
    enabled: !!debounceAmount && !!type?.value && !!countryId,
    keepPreviousData: true,
    queryFn: () =>
      calculateAdditionalPaymentEmployerCostTaxes.fetch({
        additional_payment_amount: debounceAmount,
        additional_payment_type_id: type?.value,
        country: countryId,
      }),
    onSuccess: (res) => {
      if (mode === 'create' || !selectedCompensation) setValue('tax', res?.tax_amount)
    },
  })

  const onSubmit = () => (selectedCompensation ? onEdit : onAdd)

  return (
    <ModalSide
      title={selectedCompensation ? 'Edit additional compensation' : 'Add additional compensation'}
      onClose={onClose}
      footer={false}
      okText="Save"
      primaryActions={[
        <Button type="submit" form={FORM_ID} priority="primary" size="small" key="Save" loading={loading || isLoading}>
          Save
        </Button>,
      ]}
      secondaryActions={[
        <Button type="button" priority="secondary" size="small" onClick={onClose} key="Cancel">
          Cancel
        </Button>,
      ]}
    >
      <form id={FORM_ID} onSubmit={handleSubmit(onSubmit())} noValidate>
        <div className="remo-form-input">
          <Input
            label="Title"
            addText="This term will be used in the payroll"
            data-testid="AdditionalCompensationModal-title"
            placeholder="Type..."
            isRequired
            {...register('title', {
              required: 'Title is required',
              maxLength: {
                value: 50,
                message: 'Title cannot exceed 50 characters',
              },
            })}
          />
          {errors.title && <Typography className="text_regular__14 color_red">{errors.title.message}</Typography>}
        </div>
        {(employerCostCompensations?.length && (
          <div className="remo-form-input">
            <Controller
              control={control}
              name="sub_ap_type"
              rules={{ required: 'Type is required' }}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    data-testid="AdditionalCompensationModal-type"
                    label="Type"
                    isRequired
                    options={employerCostCompensations?.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                )
              }}
            />
            {errors.type && <Typography className="text_regular__14 color_red">{errors.type.message}</Typography>}
          </div>
        )) ||
          ''}
        <div className="remo-form-input">
          <Controller
            control={control}
            name="currency"
            rules={{ required: 'Currency is required' }}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  data-testid="AdditionalCompensationModal-title"
                  label="Currency"
                  options={currencies.map((currency) => ({
                    value: currency.id,
                    label: currency.short_code,
                  }))}
                  isDisabled
                />
              )
            }}
          />
          {errors.currency && <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>}
        </div>
        <div className="remo-form-input">
          <Controller
            control={control}
            name="amount"
            rules={{
              required: 'Amount is required',
              validate: {
                minlength: (v) =>
                  /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) || 'Only 2 digits allowed after decimal point',
              },
            }}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                label="Amount"
                placeholder="0.00"
                step="0.01"
                onChange={setCurrencyChangeEvent(field.onChange)}
              />
            )}
          />
          {(+taxAmount + +amount && (
            <Typography className="text_light__12 color_text_300 mt-1">
              The estimated total amount, including employer taxes and contributions, is {+taxAmount + +amount}{' '}
              {countryCurrency?.name}
            </Typography>
          )) ||
            ''}
          {errors.amount && <Typography className="text_regular__14 color_red">{errors.amount.message}</Typography>}
        </div>
        <div className="remo-form-input">
          <Controller
            control={control}
            name="frequency"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  data-testid="AdditionalCompensationModal-frequency"
                  label="Frequency"
                  options={Object.keys(RECURRENCE_FREQUENCY_TYPES_MAP).map((key) => ({
                    value: key,
                    label: RECURRENCE_FREQUENCY_TYPES_MAP[key],
                  }))}
                />
              )
            }}
          />
          {errors.frequency && (
            <Typography className="text_regular__14 color_red">{errors.frequency.message}</Typography>
          )}
        </div>
        {watchFrequency?.value && watchFrequency.value !== RECURRENCE_FREQUENCY_TYPES.ONE_TIME ? (
          <div className="remo-form-input">
            <Styled.Occurences>
              <Styled.OccurencesCheckbox>
                <Controller
                  control={control}
                  name="hasEnd"
                  render={({ field }) => (
                    <Checkbox {...field} data-testid="AllowanceModal-hasEnd" checked={field.value} label="End after" />
                  )}
                />
              </Styled.OccurencesCheckbox>
              <Styled.OccurencesInput>
                <Input
                  data-testid="AllowanceModal-occurrences"
                  {...register('occurrences', {
                    required: watchHasEnd && 'Enter number of occurrences',
                    validate: {
                      positive: (value) => {
                        if (!watchHasEnd) return true
                        return parseInt(value, 10) > 0 || 'Must be a positive number'
                      },
                    },
                  })}
                  type="number"
                  disabled={!watchHasEnd}
                />
                <Typography className="text_regular__14">occurrences</Typography>
              </Styled.OccurencesInput>
            </Styled.Occurences>
            {errors.occurrences && (
              <Typography className="text_regular__14 color_red">{errors.occurrences.message}</Typography>
            )}
          </div>
        ) : null}
        <div className="remo-form-input">
          <Controller
            control={control}
            name="isContractual"
            render={({ field }) => (
              <Checkbox
                {...field}
                data-testid="AdditionalCompensationModal-isContractual"
                checked={field.value}
                label="Include this additional compensation in the EoR contract"
              />
            )}
          />
        </div>
      </form>
    </ModalSide>
  )
}
