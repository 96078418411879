import Button from '@atoms/Button/Button'
import { HIRING_PLAN_REMOFIRST_MAP } from '@core/constants'

export const EmployeeOffboardingHiringSummary = ({ data, onEdit }) => {
  return (
    <table>
      <thead>
        <tr>
          <th width="50%">Hiring plan</th>
          <th>
            {onEdit && (
              <div className="d-flex justify-content-end">
                <Button size="xsmall" onClick={onEdit}>
                  Edit
                </Button>
              </div>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Your hiring plan with RemoFirst</td>
          <td>{HIRING_PLAN_REMOFIRST_MAP[data?.hiring_plan]}</td>
        </tr>
        {data?.hiring_plan_note && (
          <tr>
            <td>Reason</td>
            <td>{data?.hiring_plan_note}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
