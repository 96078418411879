import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { infoIcon } from '@core/icons/icons'
import React, { ReactNode } from 'react'
import { Alert } from 'react-bootstrap'
import styled, { css } from 'styled-components'

const StyledAlert = styled(Alert)<{ $type: string; $fullWidth: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 8px;

  ${(props) => {
    switch (props.$type) {
      case 'warning':
        return css`
          background: #ffe8c2;
          color: #ffa530;

          a {
            color: #ffa530;
          }

          svg {
            fill: #ffa530;
          }
        `
      case 'success':
        return css`
          background: #dbfcee;

          p {
            color: #2db77b;
          }

          svg {
            fill: #2db77b;
          }
        `

      case 'secondary':
        return css`
          background: #ebf5fc;

          p {
            color: #4c92c7;
          }

          svg {
            fill: #4c92c7;
          }
        `
      default:
        return '#121212'
    }
  }};

  ${(props) => {
    return props.$fullWidth
      ? css`
          width: 100%;
        `
      : ''
  }}
`

const StyledAlertText = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  font-size: 13px;
`

type Props = {
  message: string | ReactNode
  type: string
  fullWidth: boolean
}

export const InfoMessage = ({ message, type, fullWidth = false }: Props) => {
  return (
    <StyledAlert $type={type} $fullWidth={fullWidth}>
      <StyledAlertText>
        <div className="mr-2">
          <Icon icon={infoIcon} fill="#878787" />
        </div>
        <Typography className="text_light__12">{message}</Typography>
      </StyledAlertText>
    </StyledAlert>
  )
}
