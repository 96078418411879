import {
  OFFBOARDING_RESIGNATION_REASONS_MAP,
  OFFBOARDING_TERMINATION_REASONS,
  OFFBOARDING_TERMINATION_REASONS_MAP,
  OFFBOARDING_TYPE,
} from '@core/constants'
import { formatSendDateNonUTC } from '@core/utils'

export const getGroupedReasons = (offboardingType) => {
  return Object.entries(
    offboardingType === OFFBOARDING_TYPE.Termination
      ? OFFBOARDING_TERMINATION_REASONS_MAP
      : OFFBOARDING_RESIGNATION_REASONS_MAP
  ).reduce((acc, [key, value]) => {
    const option = {
      value: key,
      label: value.label,
    }
    acc[value.groupLabel] = acc[value.groupLabel] ? [...acc[value.groupLabel], option] : [option]
    return acc
  }, {})
}

export const getOffboardingReasonsMap = (offboardingType) => {
  return Object.entries(
    offboardingType === OFFBOARDING_TYPE.Termination
      ? OFFBOARDING_TERMINATION_REASONS_MAP
      : OFFBOARDING_RESIGNATION_REASONS_MAP
  )
}

export const parsePayload = (data) => {
  const {
    offboardingType,
    reason,
    completedActions,
    transferToAnotherEorReason,
    noticeDate,
    isEmployeeInformed,
    isConsentToContactGiven,
    requestedLastWorkingDay,
    isOnLeave,
    leaveType,
    leaveStartDate,
    leaveEndDate,
    noticePeriodArrangement,
    supportingDocuments,
  } = data

  const payload = {
    offboarding_type: offboardingType,
    reason,
    request_type: 'offboarding',
    consent_for_contact: isConsentToContactGiven === 'true',
    requested_last_working_day: formatSendDateNonUTC(requestedLastWorkingDay),
    employee_on_leave: isOnLeave === 'true',
    notice_period_arrangement: noticePeriodArrangement,
  }

  if (offboardingType === OFFBOARDING_TYPE.Termination) {
    payload.employee_informed = isEmployeeInformed === 'true'
  }
  if (reason === OFFBOARDING_TERMINATION_REASONS.performance_issues) {
    payload.employee_performance_reason_based_actions = completedActions
  }
  if (reason === OFFBOARDING_TERMINATION_REASONS.transfer_to_another_eor) {
    payload.transfer_to_another_eor_reason = transferToAnotherEorReason
  }
  if (offboardingType === OFFBOARDING_TYPE.Resignation) {
    payload.notice_given_on = formatSendDateNonUTC(noticeDate)
  }
  if (isOnLeave === 'true') {
    payload.employee_leave_type = leaveType
    payload.employee_leave_start_date = formatSendDateNonUTC(leaveStartDate)
    payload.employee_leave_end_date = formatSendDateNonUTC(leaveEndDate)
  }
  if (supportingDocuments?.length > 0) {
    payload.support_documents = supportingDocuments
  }

  return payload
}
