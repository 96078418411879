import './AdditionalPaymentStatus.scss'

import { capitalizeFirstLetter } from '@core/utils'

export default function AdditionalPaymentStatus({ status }) {
  if (!status) return <div className="status_expense">No Status</div>
  switch (status.toLowerCase()) {
    case 'created':
    case 'pending':
      return <div className="status_ap status_created_ap">Created</div>
    case 'approved_by_company':
      return <div className="status_ap status_to_be_paid_next_month_ap">To be paid next month</div>
    case 'awaiting_payment':
      return <div className="status_ap status_to_be_paid_this_month_ap">To be paid this month</div>
    case 'invoiced':
      return <div className="status_ap status_invoiced_ap">Invoiced</div>
    case 'paid':
      return <div className="status_ap status_paid_ap">Paid</div>
    case 'canceled':
    case 'cancelled':
    case 'deleted':
      return <div className="status_ap status_cancelled_ap">Cancelled</div>
    default:
      return <div className="status_ap status_default">{capitalizeFirstLetter(status).replace('_', ' ')}</div>
  }
}
