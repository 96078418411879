import Checkbox from '@atoms/Checkbox/Checkbox'
import { FormControl, FormControlLabel, FormGroup, FormHelperText, makeStyles } from '@mui/material'
import { useController, useWatch } from 'react-hook-form'

const config = { label: 'label', value: 'value' }

export const CheckboxGroup = ({ control, label, name, options, isRequired, row, ...rest }) => {
  const {
    field: { ref, value, onChange, ...inputProps },
    formState: { errors },
  } = useController({
    name,
    control,
    defaultValue: [],
  })

  const checkboxIds = useWatch({ control, name }) || []

  const handleChange = (val) => {
    const newArray = [...checkboxIds]
    const item = val

    if (newArray.length > 0) {
      const index = newArray.findIndex((x) => x === item)

      if (index === -1) {
        newArray.push(item)
      } else {
        newArray.splice(index, 1)
      }
    } else {
      newArray.push(item)
    }

    onChange(newArray)
  }

  return (
    <div>
      <FormControl className={rest?.className}>
        {label && (
          <div className="remo-form-label text_medium__14 d-flex align-items-center">
            <span>
              {label} {isRequired && <span className="text-danger">*</span>}
            </span>
          </div>
        )}
        <FormGroup>
          {options.map((option) => (
            <div className="mb-2" key={option[config.value]}>
              <Checkbox
                {...inputProps}
                checked={value?.some((checked) => checked === option[config.value])}
                onChange={() => handleChange(option[config.value])}
                disabled={rest?.disabled}
                label={option[config.label]}
              />
            </div>
          ))}
        </FormGroup>
      </FormControl>
      <FormHelperText error variant="outlined">
        {errors?.[name]?.message || ' '}
      </FormHelperText>
    </div>
  )
}
