import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { Attachment } from '@components/attachment/attachment.component'
import { AttachmentUpload } from '@components/attachment/attachment-upload.component'
import { InfoMessage } from '@components/info-message/info-message.component'
import { Loader } from '@components/loader/loader.component'
import { commonDocumentMimeTypes, OFFBOARDING_PTO_COMMUNICATION_METHOD } from '@core/constants'
import { getContractsPaidLeaves } from '@services/contract.service'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { Label, RadioBox, RadioBoxBody, RadioContent, RadioInput } from './employee-offboarding-pto-form.styles'

export const EmployeeOffboardingPtoForm = ({ onSubmit, employee }) => {
  const {
    handleSubmit,
    watch,
    register,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      employeePtoRecords: [],
    },
  })

  const watchPtoDataCommunicationMethod = watch('ptoDataCommunicationMethod')

  const employeePaidLeavesQuery = useQuery(['getContractsPaidLeaves', employee], {
    queryFn: () => getContractsPaidLeaves(employee.id),
    enabled:
      !!employee.id &&
      watchPtoDataCommunicationMethod === OFFBOARDING_PTO_COMMUNICATION_METHOD.leaves_logged_on_platform,
  })

  const onBeforeSubmit = ({ ptoDataCommunicationMethod, employeePtoRecords }) => {
    const payload = {
      pto_data_communication_method: ptoDataCommunicationMethod,
    }
    if (ptoDataCommunicationMethod === OFFBOARDING_PTO_COMMUNICATION_METHOD.provide_pto_record) {
      payload.employee_time_off_records = employeePtoRecords
    }
    onSubmit(payload)
  }

  return (
    <form onSubmit={handleSubmit(onBeforeSubmit)} className="d-flex flex-column h-100 remo-form">
      <section className="flex-grow-1 d-flex flex-column align-items-center">
        <InfoMessage message="Accrued unused PTO (paid leave) must be paid out upon separation. Therefore, we need your assistance in accurately reconciling the PTO balance." />
        <div className="remo-form-input">
          <RadioBox>
            <RadioInput
              type="radio"
              value={OFFBOARDING_PTO_COMMUNICATION_METHOD.leaves_logged_on_platform}
              id={OFFBOARDING_PTO_COMMUNICATION_METHOD.leaves_logged_on_platform}
              {...register('ptoDataCommunicationMethod', { required: 'Choose one option' })}
            />
            <RadioBoxBody>
              <Label htmlFor={OFFBOARDING_PTO_COMMUNICATION_METHOD.leaves_logged_on_platform}>
                All used leaves have been logged and approved on RemoFirst platform
              </Label>
            </RadioBoxBody>
          </RadioBox>
          {watchPtoDataCommunicationMethod === OFFBOARDING_PTO_COMMUNICATION_METHOD.leaves_logged_on_platform && (
            <RadioContent>
              {employeePaidLeavesQuery.isLoading ? (
                <Loader />
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>Paid time-off (in days)</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Annual entitlement defined by contract</td>
                        <td>{employeePaidLeavesQuery.data?.leave_days?.paid_leave.annual_days_by_contract ?? '-'}</td>
                      </tr>
                      <tr>
                        <td>Accrued days year to date</td>
                        <td>{employeePaidLeavesQuery.data?.leave_days?.paid_leave.accrued_days ?? '-'}</td>
                      </tr>
                      <tr>
                        <td>Carryover from previous year</td>
                        <td>{employeePaidLeavesQuery.data?.leave_days?.paid_leave.carryover_days ?? '-'}</td>
                      </tr>
                      <tr>
                        <td>Ad-hoc adjustment year to date</td>
                        <td>{employeePaidLeavesQuery.data?.leave_days?.paid_leave.ad_hoc_days ?? '-'}</td>
                      </tr>
                      <tr>
                        <td>Used year to date</td>
                        <td>{employeePaidLeavesQuery.data?.leave_days?.paid_leave.used_days ?? '-'}</td>
                      </tr>
                      <tr>
                        <td>Balance</td>
                        <td>{employeePaidLeavesQuery.data?.leave_days?.paid_leave.available_days ?? '-'}</td>
                      </tr>
                    </tbody>
                  </table>
                  <InfoMessage
                    message="If there is a discrepancy between the above and your own time-off record, please use the option below to provide your record."
                    type="warning"
                  />
                </>
              )}
            </RadioContent>
          )}
          <RadioBox>
            <RadioInput
              type="radio"
              value={OFFBOARDING_PTO_COMMUNICATION_METHOD.provide_pto_record}
              id={OFFBOARDING_PTO_COMMUNICATION_METHOD.provide_pto_record}
              {...register('ptoDataCommunicationMethod')}
            />
            <RadioBoxBody>
              <Label htmlFor={OFFBOARDING_PTO_COMMUNICATION_METHOD.provide_pto_record}>
                Provide the employee’s PTO record so our team can reconcile it
              </Label>
            </RadioBoxBody>
          </RadioBox>
          {watchPtoDataCommunicationMethod === OFFBOARDING_PTO_COMMUNICATION_METHOD.provide_pto_record && (
            <RadioContent>
              <div className="remo-form-input">
                <Controller
                  control={control}
                  name="employeePtoRecords"
                  rules={{ required: 'Supporting document is required' }}
                  render={({ field }) => (
                    <AttachmentUpload
                      label="Upload employee’s time-off record"
                      addText="From the employee’s start date till today"
                      helperText="Text, word, pdf, image files up to 50 MB"
                      accept={commonDocumentMimeTypes}
                      onAttach={field.onChange}
                      onDelete={(data) => console.log(data)}
                      multiple
                      detailedFiles
                    />
                  )}
                />
                {errors.employeePtoRecords && (
                  <Typography className="text_regular__14 color_red">{errors.employeePtoRecords.message}</Typography>
                )}
              </div>
            </RadioContent>
          )}
          {errors.ptoDataCommunicationMethod && (
            <Typography className="text_regular__14 color_red">{errors.ptoDataCommunicationMethod.message}</Typography>
          )}
        </div>

        <div className="align-self-center mt-5 mb-5">
          <Button type="submit" className="align-self-end">
            Next
          </Button>
        </div>
      </section>
    </form>
  )
}
