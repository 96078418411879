import Button from '@atoms/Button/Button'
import styled from 'styled-components'

import { EmployeeOffboardingFinalPaymentSummary } from '../employee-offboarding-final-payment-summary/employee-offboarding-final-payment-summary.component'
import { EmployeeOffboardingHiringSummary } from '../employee-offboarding-hiring-summary/employee-offboarding-hiring-summary.component'
import { EmployeeOffboardingPtoSummary } from '../employee-offboarding-pto-summary/employee-offboarding-pto-summary.component'
import { EmployeeOffboardingRequestSummary } from '../employee-offboarding-request-summary/employee-offboarding-request-summary.component'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 640px;
  min-height: 100%;
`
export const EmployeeOffboardingSummary = ({ navigateStep, stepConfig, onSubmit, data, isLoading }) => {
  return (
    <Root>
      <EmployeeOffboardingRequestSummary data={data} onEdit={() => navigateStep(stepConfig.offboardingRequest.order)} />
      <EmployeeOffboardingPtoSummary data={data} onEdit={() => navigateStep(stepConfig.pto.order)} />
      <EmployeeOffboardingFinalPaymentSummary data={data} onEdit={() => navigateStep(stepConfig.finalPayment.order)} />
      <EmployeeOffboardingHiringSummary data={data} onEdit={() => navigateStep(stepConfig.hiringPlan.order)} />
      <div className="align-self-center mt-5 mb-5">
        <Button onClick={onSubmit} className="align-self-end" loading={isLoading}>
          Submit
        </Button>
      </div>
    </Root>
  )
}
