import Button from '@atoms/Button/Button'
import { EmployeeOffboardingFinalPaymentSummary } from '@features/employee-offboarding/employee-offboarding-final-payment-summary/employee-offboarding-final-payment-summary.component'
import { EmployeeOffboardingHiringSummary } from '@features/employee-offboarding/employee-offboarding-hiring-summary/employee-offboarding-hiring-summary.component'
import { EmployeeOffboardingPtoSummary } from '@features/employee-offboarding/employee-offboarding-pto-summary/employee-offboarding-pto-summary.component'
import { EmployeeOffboardingRequestSummary } from '@features/employee-offboarding/employee-offboarding-request-summary/employee-offboarding-request-summary.component'
import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 640px;
  min-height: 100%;
`

export const EmployeeOffboardingTracker = ({ data, employee }) => {
  return (
    <Root>
      <EmployeeOffboardingRequestSummary data={data} />
      <EmployeeOffboardingPtoSummary data={data} />
      <EmployeeOffboardingFinalPaymentSummary data={data} employee={employee} />
    </Root>
  )
}
