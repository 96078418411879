import Typography from '@atoms/Typography/Typography'
import { fetchUploadFile } from '@services/app.service'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { v4 } from 'uuid'

import { Styled } from './attachment.styles'
import { checkFileSizeIsTooLarge, ERROR_FILE_SIZE_TOO_LARGE } from './attachment.utils'
import { AttachmentDropzone } from './attachment-dropzone/attachment-dropzone'
import { AttachmentFile } from './attachment-file/attachment-file'

export const AttachmentUpload = ({
  accept,
  onAttach,
  onDelete,
  multiple = false,
  detailedFiles = false,
  helperText = '',
  label = '',
  addText = '',
  isRequired = false,
}) => {
  const [attachments, setAttachments] = useState([])
  const [errorText, setErrorText] = useState('')

  const { mutateAsync } = useMutation({
    mutationFn: (attachment) => fetchUploadFile(attachment.file),
    onMutate: (attachment) => {
      setAttachments((prev) => {
        return [
          ...prev.filter((item) => item.id !== attachment.id),
          {
            ...attachment,
            loading: true,
            success: false,
            error: '',
          },
        ]
      })
    },
    onSuccess: (data, attachment) => {
      setAttachments((prev) => {
        const newAttachments = [
          ...prev.filter((item) => item.id !== attachment.id),
          {
            ...attachment,
            fileId: data.file_uuid,
            loading: false,
            success: true,
            error: '',
          },
        ]

        return newAttachments
      })
    },
    onError: (error, attachment) => {
      setAttachments((prev) => {
        const newAttachments = prev.filter((item) => !item.file_uuid)

        return newAttachments
      })
    },
  })

  const handleDrop = async (acceptedFiles) => {
    setErrorText('')
    const attachmentsToUpload = acceptedFiles.map((file) => {
      if (checkFileSizeIsTooLarge(file.size)) {
        setErrorText(ERROR_FILE_SIZE_TOO_LARGE)
      }
      return {
        id: v4(),
        file,
        loading: false,
        error: checkFileSizeIsTooLarge(file.size),
        success: false,
        fileId: null,
      }
    })

    const uploadMutations = attachmentsToUpload
      .filter(({ error }) => !error)
      .map((attachment) => mutateAsync(attachment))

    try {
      const files = await Promise.all(uploadMutations)
      const fileIds = files.map(({ file_uuid }) => file_uuid)

      if (multiple) {
        if (detailedFiles) {
          onAttach([...files, ...attachments])
        } else {
          onAttach([...fileIds, ...attachments.map(({ fileId }) => fileId)])
        }
      } else if (detailedFiles) {
        onAttach(files)
      } else {
        onAttach(fileIds)
      }
    } catch (e) {
      console.log('An error has occured while uploading a file')
    }
  }

  const handleDelete = (e, index) => {
    e.stopPropagation()
    setAttachments((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
    if (attachments[index].fileId !== null) {
      onDelete(attachments[index].fileId)
    }
  }

  return (
    <>
      <div className="d-flex flex-column">
        <Typography className={classNames(['text_medium__14', !addText && 'remo-form-label'])}>
          {label} {isRequired && <span className="text-danger ml-1">*</span>}
        </Typography>
        {addText && (
          <Typography className="text_light__12 color_text_300" style={{ marginBottom: 12 }}>
            {addText}
          </Typography>
        )}
      </div>
      {multiple ? (
        <Styled.Multiple>
          <AttachmentDropzone
            multiple={multiple}
            accept={accept}
            onDrop={handleDrop}
            helperText={helperText}
            errorText={errorText}
          />
          {attachments.length > 0 && (
            <Styled.Files>
              {attachments.map(({ file, error, loading }, index) => (
                <AttachmentFile
                  key={v4()}
                  name={file.name}
                  size={file.size}
                  url={URL.createObjectURL(file)}
                  loading={loading}
                  error={error}
                  onDelete={(e) => handleDelete(e, index)}
                />
              ))}
            </Styled.Files>
          )}
        </Styled.Multiple>
      ) : (
        <Styled.Single>
          {attachments.length > 0 ? (
            <AttachmentFile
              name={attachments[0].file.name}
              size={attachments[0].file.size}
              url={URL.createObjectURL(attachments[0].file)}
              onDelete={(e) => handleDelete(e, 0)}
              error={attachments[0].error}
              loading={attachments[0].loading}
            />
          ) : (
            <AttachmentDropzone
              multiple={multiple}
              accept={accept}
              onDrop={handleDrop}
              helperText={helperText}
              errorText={errorText}
            />
          )}
        </Styled.Single>
      )}
    </>
  )
}
