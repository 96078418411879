import Button from '@atoms/Button/Button'
import { getCurrency, SplitNumber } from '@core/utils'

export const EmployeeOffboardingFinalPaymentSummary = ({ data, onEdit, employee }) => {
  return (
    <table>
      <thead>
        <tr>
          <th width="50%">Final payment</th>
          <th>
            {onEdit && (
              <div className="d-flex justify-content-end">
                <Button size="xsmall" onClick={onEdit}>
                  Edit
                </Button>
              </div>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Additional compensation</td>
          <td>
            {getCurrency(employee?.compensation.currency)} {SplitNumber(data?.additional_compensation)}
          </td>
        </tr>
        <tr>
          <td>Employee holds equipment</td>
          <td>{data?.employee_holds_equipment ? 'Yes' : 'No'}</td>
        </tr>
        {data?.employee_holds_equipment && (
          <>
            <tr>
              <td>Shipping expenses to reimburse</td>
              <td>{data?.shipping_expenses_reimbursed ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <td>Equipment return instruction given</td>
              <td>{data?.property_return_instruction ? 'Yes' : 'No'}</td>
            </tr>
          </>
        )}
        <tr>
          <td>Additional note</td>
          <td>{data?.additional_note}</td>
        </tr>
      </tbody>
    </table>
  )
}
