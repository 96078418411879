/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
export interface TimeOffPolicyDto {
  annual_days_by_contract: string
  unlimited_time_off_on?: boolean
  annual_days_rule: TimeOffAnnualDaysRule
  accrual_rule: TimeOffAccrualRule
  // allowance_rule: TimeOffAllowanceRule;
  allow_carryover: boolean
  carryover_rule: TimeOffCarryoverRule
  maximum_carryover_days_per_year: number
  carryover_expiry_amount: number
  carryover_expiry_unit: TimeOffCarryoverExpiryUnit
  sick_leave_days_rule: TimeOffSickLeaveDaysRule
}

export enum TimeOffAnnualDaysRule {
  Statutory = 'statutory',
  Recommended = 'recommended',
  TopOfMarket = 'top_of_market',
  Custom = 'custom',
}

export enum TimeOffAccrualRule {
  AllAtOnce = 'all_at_once',
  Accrued = 'accrued',
}

export enum TimeOffAllowanceRule {
  ProratedAllowance = 'prorated_allowance',
  FullAllowance = 'full_allowance',
}

export enum TimeOffCarryoverRule {
  Unlimited = 'unlimited',
  Limited = 'limited',
}

export enum TimeOffCarryoverExpiry {
  None = 'none',
  Custom = 'custom',
}

export enum TimeOffCarryoverExpiryUnit {
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
  Years = 'years',
}

export enum TimeOffSickLeaveDaysRule {
  Statutory = 'statutory',
  Recommended = 'recommended',
  Unlimited = 'unlimited',
}

export interface CountryPaidLeaves {
  statutory_annual_days: number
  recommendation_by_market_days: number
  recommendation_by_remofirst_days: number
  needed_days_to_request_first_absence: number
}

export interface CountrySpecificPaidLeaves {
  sick_days: number
  maternity_days: number
  paternity_days: number
  bereavement_days: number
}
