import { format, isValid, parse } from 'date-fns'
import moment from 'moment'
import { v4 } from 'uuid'

import { USER_GROUPS } from './constants'

export const StringToBoolean = (value) => value && JSON.parse(value)

export const SplitNumber = (num) => String(Number(num).toFixed(2)).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')

export const SplitAmount = (num) => String(Number(num)).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')

export const getDate = (date) => {
  if (moment(date).isSame(new Date(), 'week')) {
    return moment(date).format('dddd, HH:mm')
  }
  return moment(date).format('DD MMM YYYY, HH:mm')
}

export const isValidDate = (date) => (date === null ? false : !Number.isNaN(new Date(date).getTime()))

export const validateMUIDateFormat = (date, dateFormat) => {
  if (!isValidDate(date)) return false

  const formattedDate = format(new Date(date), dateFormat)
  const parsedDate = parse(formattedDate, dateFormat, new Date())

  return isValid(parsedDate)
}

export const getUTCDate = (dateString) => {
  const date = new Date(dateString)
  return isValidDate(date)
    ? new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )
    : date
}

export const formatDate = (date) => (isValidDate(date) ? format(getUTCDate(date), 'dd MMM y') : date)

export const formatSendDate = (date) => (isValidDate(date) ? format(getUTCDate(date), 'yyyy-MM-dd') : date)

export const formatSendDateNonUTC = (date) => (isValidDate(date) ? format(new Date(date), 'yyyy-MM-dd') : date)

export const formatDateTime = (date) => isValidDate(date) && format(new Date(date), 'dd MMM y HH:mm')

export const getPaymentsTotal = (payments, isPayContractor) => {
  const allSum = payments
    .filter((v) => v.ap_type !== 'DEDUCTION')
    .map((v) => (isPayContractor ? v.amount_in_contractor_payment_currency : v.amount_in_company_currency))
    .reduce((acc, item) => acc + item, 0)

  const deductionSum = payments
    .filter((v) => v.ap_type === 'DEDUCTION')
    .map((v) => (isPayContractor ? v.amount_in_contractor_payment_currency : v.amount_in_company_currency))
    .reduce((acc, item) => acc + item, 0)
  return Number((allSum - deductionSum).toFixed(2))
}

export const capitalizeFirstLetter = (value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()

export const mapEnum = (value) => value.split('_').join(' ')

export const setCurrencyChangeEvent = (changeFn) => (e) => {
  changeFn({
    ...e,
    target: { ...e.target, value: e.target.value.replace(/ /g, '') },
  })
}

/**
 *
 * @param currency object
 * @returns currency string
 */
export const getCurrency = (currency = {}) => {
  if (currency === null) return ''
  const { sign, short_code } = currency
  return sign || short_code || ''
}

export const debounce = (func, timeout = 300) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const filterKeyEnter = (handler) => {
  return (e) => {
    if (e.keyCode === 13) {
      handler(e)
    }
  }
}

export const accessibleOnClick = (handler) => (filterKeyDown) => {
  return {
    role: 'button',
    tabIndex: 0,
    onKeyDown: filterKeyDown(handler),
    onClick: handler,
  }
}

export const generateService = (fetch) => ({
  key: v4(),
  fetch,
})

export const userHasGroup = (userGroups, group) => !!userGroups.find((g) => group === g.id)

export const userHasOneOfGroups = (userGroups, groups) =>
  groups.some((group) => !!userGroups.find((g) => group === g.id))

export const userHasOneOfGroupsOrSuper = (userGroups, groups) =>
  userHasGroup(userGroups, USER_GROUPS.SUPERUSER) || groups.some((group) => !!userGroups.find((g) => group === g.id))

export const parseLinkedAccount = (id, profile, entity_name) => {
  return {
    uuid: id,
    full_name: `${profile.first_name} ${profile.middle_name} ${profile.last_name}`,
    avatar_url: profile.avatar,
    email: profile.email,
    entity_name,
  }
}

export const parseLinkedAccountsResponse = (linkedAccountsResponse) => {
  const res = []
  res.push(
    parseLinkedAccount(linkedAccountsResponse.id, linkedAccountsResponse.profile, linkedAccountsResponse.entity_name)
  )

  res.push(
    ...linkedAccountsResponse.linked_accounts.map((account) =>
      parseLinkedAccount(account.id, account.profile, account.entity_name)
    )
  )

  return res
}

export const getAddress = (address) => {
  const { address_line, street_address, city, state, living_country, postal_code } = address
  const addressList = [
    living_country?.name || '',
    state || '',
    city || '',
    street_address || '',
    address_line || '',
    postal_code || '',
  ]

  return addressList.reduce((acc, value, index) => {
    if (value) {
      // eslint-disable-next-line no-param-reassign
      acc += index ? `, ${value}` : value
    }
    return acc
  }, '')
}

export const clearKompassifyInfo = () => {
  localStorage.removeItem('KOMPASSIFY_USER_REGISTRATION_DATE')
  localStorage.removeItem('kompassifyUserSegments')
  localStorage.removeItem('kompassifyUserId')
  localStorage.removeItem('KompassifyTemplateMap')
}

export const runKompassifySurvey = (survey) => {
  window.kompassifyNpsSurvey.startNpsSurvey(survey)
}

export const formatMinutesToHours = (totalMinutes) => {
  const duration = moment.duration(totalMinutes, 'minutes')
  const hours = Math.floor(duration.asHours())
  const minutes = duration.minutes()

  return `${hours}h ${minutes}m`
}
